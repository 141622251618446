import React from "react";
import Creativity from "../../assets/portfolio/creativity.png";
import Journeyx from "../../assets/portfolio/journeyx.png";
import ArrowLink from "../../assets/portfolio/arrowlink.svg";
import Section9 from "../home/section9";

function Portfolio() {
  const portfolioItems = [
    {
      title: "JourneyX",
      description:
        "The Journey X mobile app is designed to revolutionize the way travelers plan and manage their journeys.",
      image: Journeyx,
      link: "https://journey-x.com",
    },
    {
      title: "Travovertex (World's Largest CRM)",
      description:
        "Travovertex stands as the world’s largest and most advanced CRM tailored specifically for the travel industry.",
      image: Journeyx,
      link: "https://journey-x.com",
    },
    {
      title: "FBT Adventures Website",
      description:
        "The FBT Adventures website is a dynamic and visually stunning platform that showcases the wide array of travel experiences offered by FBT Adventure UAE.",
      image: Journeyx,
      link: "https://journey-x.com",
    },
  ];

  return (
    <div>
      {/* Section 1 */}
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] mt-20 font-light">
              Where{" "}
              <span className="font-bold text-primary">Creativity</span> Meets{" "}
              <span className="font-bold text-seconday">Precision</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              With over{" "}
              <span className="text-primary font-bold">15 successful</span> AI &
              ML projects under our belt, we pride ourselves on delivering
              exceptional digital solutions that drive results.
            </div>
          </div>
          <div className="mt-28">
            <img src={Creativity} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>

      {/* Portfolio Section */}
      <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 ">
        <div className="grid grid-cols-1 gap-5 justify-center text-center">
          <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
            Our
            <span className="text-primary font-bold"> Portfolio</span>
          </div>

          <div className="grid grid-cols-1 gap-10 justify-center text-center md:grid-col-2 lg:grid-cols-3">
            {portfolioItems.map((item, index) => (
              <div className="containers" key={index}>
                <img src={item.image} alt={item.title} className="images shadow-inner" />
                <div className="overlay">
                  <div className="texts">
                    <h1>{item.title}</h1>
                    <h5 className="mt-4 mb-4">{item.description}</h5>
                    <a
                      href={item.link}
                      target="_blank"
                      className="flex flex-row items-center justify-center gap-2 hover:underline"
                      rel="noreferrer"
                    >
                      Visit <img src={ArrowLink} alt="techagg" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
