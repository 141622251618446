import React, { useState } from 'react';
import Logo from "../../assets/logo.svg";
import "./newheader.css";
import { NavLink } from 'react-router-dom';
import Servicemodal from '../ServicesModal/Servicemodal';
import ConnectNow from '../../pages/connectnow/ConnectNow';

const NewHeader = () => {
    const [servicemodal, setServicemodal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // State to toggle the menu visibility

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const toggleMenu = () => setMenuOpen(!menuOpen); // Function to toggle the menu

    return (
        <div className='newheader'>
            <div className='logo'>
                <NavLink to="/">
                    <img src={Logo} alt='website logo' />
                </NavLink>
            </div>
            {/* Apply the 'active' class when menuOpen is true */}
            <div className={`links ${menuOpen ? 'active' : ''}`}>
                <p onClick={() => setServicemodal(!servicemodal)}>Services <i className="fa-solid fa-chevron-down"></i></p>
                <p><NavLink to="/aboutus">About us</NavLink></p>
                <p><NavLink to="/portfolio">Portfolio</NavLink></p>
                <p><NavLink to="/careers">Careers</NavLink></p>
                <p><NavLink to="/contactus">Contact Us</NavLink></p>
            </div>
            <div className='headerright'>
                <div className='connectnow-btn'>
                    <button onClick={openModal}>Connect Now</button>
                    <ConnectNow isOpen={isModalOpen} onClose={closeModal} />
                </div>
                <button className='bars' onClick={toggleMenu}><i className="fa-solid fa-bars"></i></button>
            </div>
            {servicemodal && <Servicemodal setServicemodal={setServicemodal} />}
        </div>
    );
}

export default NewHeader;
