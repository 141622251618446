import React from 'react'
import "./servicemodal.css"
import { NavLink } from 'react-router-dom'

const Servicemodal = ({ setServicemodal }) => {
    return (
        <div className='service-modal' onClick={() => setServicemodal(false)}>
            <div className='servicemodal-container'>
                <div className='servicemodal-left'>
                    <p><NavLink to="/services/Ai&ml">AI & Machine Learning</NavLink></p>
                    <p><NavLink to="/services/appdevelopment">App Development</NavLink></p>
                    <p><NavLink to="/services/blockchain">Blockchain & Decentralized <br /> Application</NavLink></p>
                    <p><NavLink to="/services/cybersecurity&compliance">Cybersecurity & Compliance</NavLink></p>
                    <p><NavLink to="/services/dataengineeringdevops">Data Engineering & DevOps</NavLink></p>
                </div>
                <div className='servicemodal-left'>
                    <p><NavLink to="/services/transformationautomation">Digital Transformation & <br /> Automation</NavLink></p>
                    <p><NavLink to="/services/graphicdesigns">Graphic & UI/UX Design</NavLink></p>
                    <p><NavLink to="/services/iot">IoT & Smart Solutions</NavLink></p>
                    <p><NavLink to="/services/webdevelopment">Web Development</NavLink></p>
                </div>
            </div>
        </div>
    )
}

export default Servicemodal
