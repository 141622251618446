import React, { Suspense, useEffect, useState } from 'react';

const Section1 = React.lazy(() => import('./section1'));
const Section2 = React.lazy(() => import('./section2'));
const Section3 = React.lazy(() => import('./NewSection'));
const Section4 = React.lazy(() => import('./section4'));
const Section5 = React.lazy(() => import('./section5'));
const Section6 = React.lazy(() => import('./section6'));
const Section7 = React.lazy(() => import('./NewSection7'));
const Section8 = React.lazy(() => import('./section8'));
const Section9 = React.lazy(() => import('./section9'));


const Newind = () => {
    const [visibleSections, setVisibleSections] = useState([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setVisibleSections((prevSections) => {
                            if (!prevSections.includes(entry.target.id)) {
                                return [...prevSections, entry.target.id];
                            }
                            return prevSections;
                        });
                    }
                });
            },
            { threshold: 0.1 }
        );

        document.querySelectorAll('.section').forEach((section) => {
            observer.observe(section);
        });

        return () => {
            observer.disconnect();
        };
    }, []);
    return (
        <>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section1" className="section">
                    {visibleSections.includes('Section1') && <Section1 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section2" className="section">
                    {visibleSections.includes('Section2') && <Section2 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section3" className="section">
                    {visibleSections.includes('Section3') && <Section3 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section4" className="section">
                    {visibleSections.includes('Section4') && <Section4 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section5" className="section">
                    {visibleSections.includes('Section5') && <Section5 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center" >Loading...</div>}>
                <div id="Section6" className="section">
                    {visibleSections.includes('Section6') && <Section6 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section7" className="section">
                    {visibleSections.includes('Section7') && <Section7 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section8" className="section">
                    {visibleSections.includes('Section8') && <Section8 />}
                </div>
            </Suspense>
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <div id="Section9" className="section">
                    {visibleSections.includes('Section9') && <Section9 />}
                </div>
            </Suspense>
        </>
    )
}

export default Newind;
