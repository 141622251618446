import React from "react";
import Banner from "../../assets/Service/blockchain/blockchainbanner1.svg";
import Consultation from "../../assets/Service/blockchain/consultation.png";
import Banner2 from "../../assets/Service/blockchain/blockchainbanner2.svg";
import Implementation from "../../assets/Service/blockchain/implementation.png";
import Compliance from "../../assets/Service/blockchain/compliance.png";
import Design from "../../assets/Service/blockchain/blockchaindesign.png";
import Continuous from "../../assets/Service/blockchain/continuous.png";
import Dapp from "../../assets/Service/blockchain/dapp.png";
import Flutter from "../../assets/Service/appdev/flutter.svg";
import ReactJs from "../../assets/Service/appdev/reactnative.svg";
import Python from "../../assets/Service/appdev/python.svg";
import Swift from "../../assets/Service/appdev/swift.svg";
import NodeJS from "../../assets/Service/appdev/nodejs.svg";
import AndriodStudio from "../../assets/Service/appdev/andriodstudio.svg";
import Vue from "../../assets/Service/appdev/vue.svg";
import Section9 from "../home/section9";
import WhyChooseUs from "../../assets/Service/blockchain/whyChooseUs.svg";
import ArrowRight from "../../assets/Service/arrowRight.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function blockChain() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col mt-20 gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Blockchain &</span>
              <span className="font-bold text-seconday"> Decentralized</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              We’ve delivered secure, blockchain-powered solutions for numerous
              clients, ensuring transparency and trust in every transaction and
              decentralized system.
            </div>
            {/* <button className="bg-gradient-to-r from-gradient-3 to-gradient-4 w-fit text-white p-4 rounded-lg">
              Let’s Connect
            </button> */}
          </div>
          <div className="mt-24">
            <img src={Banner} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold"> Choose us </span>
              <span className="">
                for <br />
              </span>
              <span className="text-seconday font-bold">
                Blockchain & Decentralized Applications
              </span>
            </div>
            <div>
              <img src={WhyChooseUs} alt="techaag" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold"> Blockchain & </span>
              <span className="text-seconday font-bold">
                Decentralized Application Process
              </span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row items-center gap-5">
                <div className="p-4 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={Consultation} alt="techaag" className="w-10" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Initial Consultation & Feasibility Study
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Needs Assessment:
                    </span>{" "}
                    Understanding your business requirements and evaluating the
                    feasibility of blockchain solutions.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Customized Strategy:
                    </span>{" "}
                    Developing a tailored blockchain strategy that aligns with
                    your business goals.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-1 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                  <img src={Design} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Blockchain Design & Development
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Solution Design:
                    </span>{" "}
                    Designing secure and scalable blockchain solutions to meet
                    your specific needs.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Smart Contracts:
                    </span>{" "}
                    Developing and implementing smart contracts to automate
                    transactions and reduce fraud.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-4 rounded-md bg-gradient-to-r from-[#6DE0F6] to-[#65D0E5]">
                  <img src={Dapp} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Decentralized Application (DApp) Development
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      DApp Design:
                    </span>{" "}
                    Creating decentralized applications that enhance operational
                    efficiency and foster trust.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Integration Services: Seamlessly integrating DApps into
                      your existing systems and workflows.
                    </span>{" "}
                    Seamlessly integrating DApps into your existing systems and
                    workflows.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-4 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                  <img src={Implementation} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Implementation & Deployment</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Seamless Deployment:
                    </span>{" "}
                    Ensuring a smooth deployment of blockchain solutions with
                    minimal disruption to your operations.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Training & Support: Providing training and ongoing support
                      to ensure effective use of blockchain technology.
                    </span>{" "}
                    Providing training and ongoing support to ensure effective
                    use of blockchain technology.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                  <img src={Continuous} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Continuous Monitoring & Optimization
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Performance Monitoring:
                    </span>{" "}
                    Continuously monitoring the performance of blockchain
                    solutions to ensure optimal results.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Regular Updates:
                    </span>{" "}
                    Regularly updating and optimizing blockchain solutions based
                    on new developments and insights.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                  <img src={Compliance} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Compliance & Security</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Regulatory Adherence:
                    </span>{" "}
                    Ensuring compliance with relevant industry standards and
                    regulations.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Advanced Security Measures:
                    </span>{" "}
                    Implementing advanced security measures to protect your
                    blockchain solutions from threats.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={Consultation} alt="techaag" className="w-10" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Initial Consultation & Feasibility Study
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Needs Assessment:
                        </span>{" "}
                        Understanding your business requirements and evaluating
                        the feasibility of blockchain solutions.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Customized Strategy:
                        </span>{" "}
                        Developing a tailored blockchain strategy that aligns
                        with your business goals.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-1 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                      <img src={Design} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Blockchain Design & Development
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Solution Design:
                        </span>{" "}
                        Designing secure and scalable blockchain solutions to
                        meet your specific needs.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Smart Contracts:
                        </span>{" "}
                        Developing and implementing smart contracts to automate
                        transactions and reduce fraud.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#6DE0F6] to-[#65D0E5]">
                      <img src={Dapp} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Decentralized Application (DApp) Development
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          DApp Design:
                        </span>{" "}
                        Creating decentralized applications that enhance
                        operational efficiency and foster trust.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Integration Services: Seamlessly integrating DApps
                          into your existing systems and workflows.
                        </span>{" "}
                        Seamlessly integrating DApps into your existing systems
                        and workflows.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                      <img
                        src={Implementation}
                        alt="techaag"
                        className="w-16"
                      />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Implementation & Deployment
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Seamless Deployment:
                        </span>{" "}
                        Ensuring a smooth deployment of blockchain solutions
                        with minimal disruption to your operations.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Training & Support: Providing training and ongoing
                          support to ensure effective use of blockchain
                          technology.
                        </span>{" "}
                        Providing training and ongoing support to ensure
                        effective use of blockchain technology.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                      <img src={Continuous} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Continuous Monitoring & Optimization
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Performance Monitoring:
                        </span>{" "}
                        Continuously monitoring the performance of blockchain
                        solutions to ensure optimal results.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Regular Updates:
                        </span>{" "}
                        Regularly updating and optimizing blockchain solutions
                        based on new developments and insights.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                      <img src={Compliance} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Compliance & Security</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Regulatory Adherence:
                        </span>{" "}
                        Ensuring compliance with relevant industry standards and
                        regulations.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Advanced Security Measures:
                        </span>{" "}
                        Implementing advanced security measures to protect your
                        blockchain solutions from threats.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px]  font-light mb-5">
          Technology we use for
          <br />
          <span className="font-bold text-primary"> AI & </span>
          <span className="font-bold text-seconday">Machine Learning</span>
        </div>
        <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-5 justify-center items-center hidden md:grid">
            {[
              { name: "Flutter", image: Flutter },
              { name: "Python", image: Python },
              { name: "React", image: ReactJs },
              { name: "Swift", image: Swift },
              { name: "Vue", image: Vue },
              { name: "Node.js", image: NodeJS },
              { name: "Android Studio", image: AndriodStudio },
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                  <img src={item.image} alt={item.name} className="w-full" />
                </div>
                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { name: "Flutter", image: Flutter },
                { name: "Python", image: Python },
                { name: "React", image: ReactJs },
                { name: "Swift", image: Swift },
                { name: "Vue", image: Vue },
                { name: "Node.js", image: NodeJS },
                { name: "Android Studio", image: AndriodStudio },
              ].map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-auto h-32">
                    <img src={item.image} alt={item.name} className="w-full" />
                    <p className="text-center mt-2">{item.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:px-5">
            <img
              src={Banner2}
              alt="techaag"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto"
            />
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-5 relative p-5 md: mb-10 py-20 lg:px-15">
        <div className="grid grid-cols-1 gap-5 justify-between items-center md:grid-cols-2">
          <div className="font-bold text-lg text-center w-full md:text-left md:w-3/4 md:text-2xl">
            Choose us for Blockchain & Decentralized Application
          </div>
          <div className="flex justify-center md:justify-end"></div>
        </div>
      </div>
    </div>
  );
}

export default blockChain;
