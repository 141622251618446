import React from "react";
import Banner1 from "../../assets/product/globalDistributionSystem/banner1.svg";
import Banner2 from "../../assets/product/globalDistributionSystem/banner2.svg";
import Banner3 from "../../assets/product/globalDistributionSystem/banner3.svg";
import Banner4 from "../../assets/product/globalDistributionSystem/banner4.svg";

function GlobalDistributionSystem() {
    const headings = [
        { id: "sabre-system", title: "Sabre System" },
        { id: "glileo", title: "Glileo" },
        { id: "amandeus-system", title: "Amandeus System" },
    ];

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div>
            <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                <div className="grid grid-cols-1 justify-between items-center gap-10 md:grid-cols-2">
                    <div className="flex flex-col mr-5 gap-5 mt-20">
                        <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
                            <span className="font-bold text-primary">Global </span>
                            <span className="font-bold text-seconday">Distribution System (GDS)</span>
                        </div>
                        <div className="text-paragraph font-normal text-base text-justify md:mb-10">
                            A global distribution system (GDS) is a computerised network system owned or operated by a company that enables transactions between travel industry service providers, mainly airlines, hotels, car rental companies, and travel agencies. The GDS mainly uses real-time inventory (for e.g. number of hotel rooms available, number of flight seats available, or number of cars available) to service providers. Travel agencies traditionally relied on GDS for services, products and rates in order to provide travel-related services to the end consumers. Thus, a GDS can link services, rates and bookings consolidating products and services across all three travel sectors: i.e., airline reservations, hotel reservations, car rentals.
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full h-[70%] mt-20">
                        <img src={Banner1} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>
            </div>

            {/* Table of Contents */}
            <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                <div className="font-bold text-xl md:text-2xl">
                    Table of Content
                </div>
                <div className="container flex flex-row gap-7 p-5 mb-10 mt-4">
                    {headings.map((heading) => (
                        <div
                            key={heading.id}
                            className="p-3 font-bold w-fit border border-gray-400 rounded-lg bg-background cursor-pointer text-center"
                            onClick={() => scrollToSection(heading.id)}>
                            {heading.title}
                        </div>
                    ))}
                </div>
            </div>

            {/* Sections */}

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="sabre-system" className="flex flex-col gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Sabre System
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">

                        <div className="flex flex-col gap-2 items-start">
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    BT is a leading travel with advance technology company and its help to integrates Sabre GDS for travel agencies to deliver them best in class travel software. We enable travel agents to be sabre GDS connected. Most of travel websites, which are connected with sabre GDS, do have flights, hotels, cars, cruise, rails and other value added services. All the travel data romeo and juliet persuasive essay through the sabre system, comes in real time. FBT employeess 123+ highly skilled professionals in travel technology domain, at its best in class development center located at Delhi. The team is qualified to deliver top class sabre gds system and well trained in web services integration, user/sub-user modules and XML live certification process.
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                        <img src={Banner2} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Features
                            </div>

                            <div className="py-3 px-3  mt-4 w-fit">
                                <ol className="list-disc text-paragraph">
                                    • Sabre GDS is supported (travel inventory) by more than 400 flight carriers, 125000 hotels, 200 tour operators and several car and cruise providers. <br />
                                    • Sabre GDS system is connected to more than 400,000 travel agents worldwide, who are serving to end clients <br />
                                    • Sabre system processes more than a million travel booking transactions per minute during the high season. <br />
                                    • Hotel Booking System <br />
                                    • Sabre GDS also provides great support to travel agent through the online chat, call and email support. <br />
                                    • Bus Reservation System• Sabre’s new mobile friendly, sub-second response time shopping and intelligence APIs enable developers to build new capabilities for the travel industry.
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center w-[40%] mt-14 md:mt-0">

                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />


            </div>

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="glileo" className="flex flex-col gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Glileo
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">

                        <div className="flex flex-col gap-2 items-start">
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    Galileo system commands lead position in airline software and connected to almost all the leading airlines. Galileo GDS provides solutions to travel agencies by offering the desktop solutions (search & book) and web services XML to connect to core database. Galileo GDS offers flights, hotels, cars, cruise, holiday packages among others. Galileo GDS gives you access to a world of content and functionality through web services APIs and desktop connection. It’s designed to run alongside your existing API solutions for an efficient and low-risk implementation. Galileo GDS provides you access to worldwide low cost carriers, high speed rail providers and multi-source content via a single API can help you clear the clutter of multiple programming interfaces, while saving valuable time and money.
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                        <img src={Banner3} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Features
                            </div>

                            <div className="py-3 px-3  mt-4 w-fit">
                                <ol className="list-disc text-paragraph">
                                    With Galileo GDS (web services integration), we offer following: <br />
                                    • Online Travel Agent Software (including B2B, B2C, B2B2C, Corporate module) <br />
                                    • Single or Multiple PCC interface <br />
                                    • Flight Reservation System• Hotel Booking System• Car Reservation System <br />
                                    • Bus Reservation System• Modules for Rails / Visa / Cruise / Holiday Packages Learn More About Travelport Distribution System.
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center w-[40%] mt-14 md:mt-0">

                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />


            </div>

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">
                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="amandeus-system" className="flex flex-col gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Amadeus System
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                    <div className="flex-1 order-2 md:order-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    Amadeus GDS system had maintained its position as the global leader in travel technology and travel distribution solutions for tourism industry, by constantly introducing innovations. Set of solutions like e power suites, web services, APIs and other value added services, let client choose what they like. Amadeus GDS tools are built to deliver the professional travel portal solutions. It helps the agents to deliver comprehensive travel itineraries quickly and efficiently. FBT technology, being a leading Amadeus system development company, integrates Amadeus GDS to deliver best in class travel software to global travel management companies. We integrate Amadeus GDS to access the inventory for flights, hotels, transfers and cruise through web services given by Amadeus. Client’s credentials are used for the authentication and data processing. As a travel technology company, we deliver custom travel software with B2C, B2C, & API corporate module and white-label. Amadeus GDS system has all the core competencies and merits to succeed in the long run, not only because it’s a comprehensive travel management system, but also its diversity in aggregating the travel contents. Over 90,000 travel agencies globally, use the Amadeus gds system. Amadeus is structured around two major competencies, GDS and travel IT solutions. Amadeus system also provides airlines the next gen DCS – departure control systems to airlines. Amadeus IT team processes the transaction processor for the global travel and tourism industry. Amadeus is a member of IATA, OTA and SITA. Its IATA airline designator code is 1A.
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                        <img src={Banner4} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                    <div className="flex-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Features
                            </div>

                            <div className="py-3 px-3 mt-4 w-fit">
                                <ol className="list-disc text-paragraph">
                                    • In Inventory Management <br />
                                    • Reservation process <br />
                                    • PNR Generation <br />
                                    • Passenger Profiling
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center w-full md:w-[40%] mt-14 md:mt-0"></div>
                </div>
            </div>

        </div>
    );
}

export default GlobalDistributionSystem;
