import React from "react";
import Banner1 from "../../assets/product/travelLabelManagement/banner1.svg";
import Banner2 from "../../assets/product/travelLabelManagement/banner2.svg";
import Banner3 from "../../assets/product/travelLabelManagement/banner3.svg";

function TravelPortalManagement() {
  const headings = [
    { id: "web-based-b2c", title: "Web Based B2C" },
    { id: "b2b-travel-portal", title: "B2B Travel Portal" },
  ];

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5 mt-20">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Travel Portal </span>
              <span className="font-bold text-secondary">Management</span>
            </div>
            <div className="text-paragraph font-normal text-base text-justify md:mb-10">
              B2C travel portal is an online booking engine with flights, hotels, transfers, sightseeing and packages modules.
              It's also known as IBE, which aggregates and distributes information of flights, hotels, cars, packages to global B2C clients to enable them search and book online.
              B2C travel portal software can be integrated with leading GDS systems like Amadeus, Travelport, Sabre and third party APIs / XMLs and LCCs.
              Demand of B2C travel portals is surging in India, UAE, Saudi Arabia, United States, Malaysia and Far East countries.
            </div>
          </div>
          <div className="mt-14">
            <img src={Banner1} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
          </div>
        </div>
      </div>

      {/* Table of Contents */}
      <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
        <div className="font-bold text-xl md:text-2xl">
          B2C Travel Portal
        </div>
        <div className="container grid grid-cols-2 gap-2 p-5 mb-10 mt-4 lg:px-15 md:grid-cols-4">
          {headings.map((heading) => (
            <div
              key={heading.id}
              className="py-3 px-3 font-bold w-fit border border-gray-400 rounded-lg bg-background cursor-pointer text-center"
              onClick={() => scrollToSection(heading.id)}>
              {heading.title}
            </div>
          ))}
        </div>
      </div>

      {/* Sections */}
      <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

        <div className="flex flex-col-reverse md:flex-row">
          <div className="flex-1">
            <div id="web-based-b2c" className="flex flex-col gap-2 mt-8 items-start">
              <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                Web Based B2C
              </div>
              <div className="px-5">
                <ol className="list-disc text-paragraph">
                  B2C web based Booking System offers the correct devices that make the booking capacity quicker and less difficult for a wide range of customers - corporate or end-clients. Our answer is a capacity rich B2C booking motor combined with eye-getting easy to use outlines. B2C Portal Solution is intended for organizations expecting to build gainfulness by offering inventories straightforwardly to clients.
                </ol>
              </div>
            </div>

            <div id="b2c-dashboard" className="flex flex-col gap-2 items-start">
              <div className="py-3 px-3 font-bold w-fit bg-background">
                B2C Dashboard
              </div>
              <div className="px-5">
                <ol className="list-disc text-paragraph">
                  • Can View Their Bookings<br />
                  • Can share their itinerary on social media <br />
                  • Can manage their account info<br />
                  • Print/Email their itinerary<br />
                  • Cancellations/Modifications : The B2C Clients can cancel or edit hotel bookings from this page but this feature is locked by default and can be activated by super admin for all B2C clients.
                </ol>
              </div>
            </div>
          </div>

          <div className="flex items-center mt-14 md:mt-0">
            <img src={Banner2} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
          </div>
        </div>

        {/* Horizontal Line */}
        <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />

        <div id="b2b-travel-portal" className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background border border-gray-400 rounded-lg mb-10">
            B2B Travel Portal
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              FBT team itself is a bunch of passionate travelers who started this with one unified aim, which is to make travelling easy for worldwide. From Air Fares,Hotel, Bus, Travel insurance,Visa, Forex,Holiday,everything comes at an ease.We take pride in being the most efficient in the industry with complete B2B rate. FBT operates on a dynamic API switch module and is powered by some of the most secure gateways. What makes FlyBird Tourism so unique is that it itself acts as a Meta aggregation in terms of flights and hotels, hosting multiple inventories in a single platform to Tourism with multi sector, who are looking for new avenues to expand their businesses with customer network, we have a wide range of exciting what is an open thesis statement deal in Airlines, Holidays, Hotels, Interiors Designs etc. Rapidly Our company became the preferred choice of customers, Our company rise has been lead by the vision and the spirit of each one of its employees, for whom no idea was too big and no problem too difficult. With untiring innovation and determination, Our company proactively began to diversify its product offering, adding a variety of online and offline products and services.
            </ol>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full md:w-[70%]">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            B2B Travel Portal
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              We as a online travel portal company, we offer b2b travel portal development services to online b2b travel agency in India. Our travel portal consists of GDS/third party API Integration for Flight booking, Hotel booking and Car booking services. We offer all kind of travel portal solutions for b2b travel agency across the planet.            </ol>
          </div>
        </div>


        <div className="flex flex-col-reverse md:flex-row">
          <div className="flex-1">
            <div id="b2b-agent-page" className="flex flex-col gap-2">
              <div className="py-3 px-3 font-bold w-fit bg-background">
                B2B Agent Page
              </div>
              <div className="px-5">
                <ol className="list-disc text-paragraph">
                  • Agents can use their own logo and Agency Title<br />
                  • Can add markups in the same way<br />
                  • Can search for hotels<br />
                  • Can run their Booking reports/Cancellation Reports<br />
                  • Can view their Profits<br />
                  • Can view their Deposits<br />
                  • Can make a new reservation<br />
                  • Can Send Queries to Superadmin from within their page<br />
                  • Automated Cancellations/Modifications: The agents can cancel or edit hotel bookings from this page but this feature is locked by default and can be activated by super admin for each B2B agent.
                </ol>
              </div>
            </div>
          </div>
          <div className="flex items-center mt-10 md:mt-0">
            <img src={Banner3} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
          </div>
        </div>

      </div>
    </div>
  );
}

export default TravelPortalManagement;
