import React from "react";
import Banner1 from "../../assets/product/onlineReservationSystem/banner1.svg";
import Banner2 from "../../assets/product/onlineReservationSystem/banner2.svg";
import Banner3 from "../../assets/product/onlineReservationSystem/banner3.svg";
import Banner4 from "../../assets/product/onlineReservationSystem/banner4.svg";
import Banner5 from "../../assets/product/onlineReservationSystem/banner5.svg";
import Banner6 from "../../assets/product/onlineReservationSystem/banner6.svg";

function OnlineReservationSystem() {
    const headings = [
        { id: "flight", title: "Flight" },
        { id: "hotel", title: "Hotel" },
        { id: "car", title: "Car" },
        { id: "bus", title: "Bus" },
    ];

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div>
            <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
                    <div className="flex flex-col gap-5 mt-20">
                        <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
                            <span className="font-bold text-primary">Online </span>
                            <span className="font-bold text-seconday">Reservation System</span>
                        </div>
                        <div className="text-paragraph font-normal text-base text-justify md:mb-10">
                            An online booking system is a software you can use for reservation management. They allow tour and activity operators to accept bookings online and better manage their phone and in person bookings.
                            <br /><br /> A hotel reservation system works by processing secure online reservations made through a hotel's website. The data is then passed onto a backend system which can be accessed by hotels to manage bookings.
                        </div>
                    </div>
                    <div className="mt-14">
                        <img src={Banner1} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>
            </div>

            {/* Table of Contents */}
            <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                <div className="font-bold text-xl md:text-2xl">
                    Table of Content
                </div>
                <div className="container flex flex-row gap-7 p-5 mb-10 mt-4">
                    {headings.map((heading) => (
                        <div
                            key={heading.id}
                            className="p-3 font-bold w-fit border border-gray-400 rounded-lg bg-background cursor-pointer text-center"
                            onClick={() => scrollToSection(heading.id)}>
                            {heading.title}
                        </div>
                    ))}
                </div>
            </div>

            {/* Sections */}

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="flight" className="flex flex-col gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Flight
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row items-center justify-center">
                    <div className="flex-1">

                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Flight Suppliers
                            </div>
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    With the growing trend of OTA – online travel agency, demand for flight suppliers has also seen north trend. IATA and NON IATA travel agents, all are looking for flight suppliers to integrate the web services in their web portals. While IATA is seeking the GDS systems like Amadeus, Travel port, Sabre, Non IATA agents are looking for third party flight APIs FBT TECHNOLOGY integrates a wide range of GDS systems and APIs from major flight, hotels, transfers and cruise providers / suppliers & consolidators. We work with all the major travel consolidators and integrate following -
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-center w-[40%] mt-14 md:mt-0">
                        <img src={Banner2} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                <div className="flex flex-col-reverse items-center justify-center md:flex-row">
                    <div className="flex-1">

                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Flight Consolidators
                            </div>
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    Travel agents, who have their own suppliers, can grow their business through their B2B and B2C travel portals. The amount of enormous access to worldwide content, everything exercised to just single point integration has redefined the global distribution system process for travel companies, travel agencies and end users. With API and XML of various travel suppliers, one can integrate its online booking system to global distribution system network & third party APIs and avail access to worldwide flights, low cost carriers, hotels, high speed rail providers, multi-source content from travel suppliers worldwide via single travel API. It also provides access to all the next generation travel technology of global distributors. Travel APIs have taken the travel industry to next level from the traditional booking process to one of the most sophisticated search & book process online by increasing its efforts, operations, efficiency and customer service. In fact the same should be the bottom line of your travel agency as well.
                                </ol>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Top Flight Suppliers
                            </div>

                            <div className="flex flex-row ">
                                <div className="px-5">
                                    <ol className=" text-paragraph">
                                        • GDS systems like Amadeus<br />
                                        • Travelport / Galileo <br />
                                        • Sabre <br />
                                        • Abacus <br />
                                        • Travelsky <br />
                                        • Infini
                                    </ol>
                                </div>

                                <div className="px-5">

                                    <ol className=" text-paragraph">
                                        • Axess <br />
                                        • Topas <br />
                                        • Mystifly(Myfarebox) <br />
                                        • TBO <br />
                                        • Riya <br />
                                        • Multicom
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-center w-[40%] mt-14 md:mt-0">
                        <img src={Banner3} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />


            </div>

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="hotel" className="flex flex-col gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Hotel
                            </div>
                        </div>
                    </div>
                </div>



                <div className="flex flex-col-reverse items-center justify-center md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">

                                <div className="flex flex-col gap-2 items-start">
                                    <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                        Hotel Suppliers
                                    </div>
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            Hotel Consolidators are travel management companies who provide large database of hotel contents through API or XML format to various travel agents and travel startups or even to large OTAs. These hotel contents comes with all the features like rates, offers, room availability, add-on features, videos, images, facilities and many more. The inventory associated with these hotel suppliers are connected to one's hotel booking system so that their own customers can book hotels directly from their website. The B2B interface allows even the sub-agents to access the online booking system (to increase sales or bookings) to book hotels and earn commission on every booking.                                </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                How Hotel Suppliers work?
                            </div>
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    Hotel suppliers are connected to travel agents and tour operators of the major cities and destinations to distribute the properties or hotel inventories. In comparison to individuals who contact hotels directly and ask for discounts, the consolidators are able to achieve deep discounts due to their buying power. Hotels are willing to offer dramatically reduced room rates to consolidators because they know it will yield a greater number of reservations. Many hotels are interested in selling room blocks (allotments) to consolidators, because they know that discounted rooms mean more business in the long run and subsequently a greater profit for them. To reach maximum local customers (end customer), hotel xml suppliers have started associating with travel agents and tour operators from each destination to sell their hotel inventories hence facilitate the distribution process.                                </ol>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Top Hotel Suppliers
                            </div>

                            <div className="flex flex-row ">
                                <div className="px-5">
                                    <ol className=" text-paragraph">
                                        • GRN Connect <br />
                                        • Hotels pro <br />
                                        • Getabed <br />
                                        • Room XML <br />
                                        • TBO <br />
                                        • Expedia <br />
                                        • Booking.com
                                    </ol>
                                </div>

                                <div className="px-5">

                                    <ol className=" text-paragraph">
                                        • Akbar Travels <br />
                                        • RIya <br />
                                        • GDS systems like Amadeus <br />
                                        • Travelport / Galileo <br />
                                        • Sabre <br />
                                        • Abacus
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center ml-4 w-[35%] mt-14 md:mt-0">
                        <img src={Banner4} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />


            </div>


            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="car" className="flex flex-col gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Car
                            </div>
                        </div>
                    </div>
                </div>



                <div className="flex flex-col-reverse items-center justify-center md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">

                                <div className="flex flex-col gap-2 items-start">
                                    <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                        Car Suppliers
                                    </div>
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            Travel suppliers have provided opportunity to many IATA and NON IATA travel agents to build their own booking system without any knowledge on travel industry. Travel suppliers includes a set of suppliers from hotel, car, bus, tours, transfers, cruise, payment gateway and insurance industry accessed in API format and integrated through web services.
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Car Consolidators
                            </div>
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    Car suppliers are consolidating the global data to provide great inventory to portals and apps. It is all because of the technological advancement, that the car rental companies are capable of providing a smooth and organized service to its customers along with hassle free journey. Car rental software and taxi booking app are to be credited for making stride in the world of travel Management.
                                </ol>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Major Car aggregateor / Car Suppliers are:
                            </div>

                            <div className="flex flex-row ">
                                <div className="px-5">
                                    <ol className=" text-paragraph">
                                        • Car Trawler <br />
                                        • A2B <br />
                                        • Economy Bookings <br />
                                        • Rental cars <br />
                                        • Travel auto <br />
                                        • VIP cars
                                    </ol>
                                </div>

                                <div className="px-5">

                                    <ol className=" text-paragraph">
                                        • Clear Car Rentals <br />
                                        • For Transfers :GTA & Hotel beds <br />
                                        • Savari <br />
                                        • Mozio <br />
                                        • Zoom Car
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center ml-4 w-[40%] mt-14 md:mt-0">
                        <img src={Banner5} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />


            </div>

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse items-center justify-center md:flex-row">
                    <div className="flex-1">
                        <div id="bus" className="flex flex-col gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Bus
                            </div>
                        </div>
                    </div>
                </div>



                <div className="flex flex-col-reverse items-center justify-center md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">

                                <div className="flex flex-col gap-2 items-start">
                                    <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                        Bus Suppliers
                                    </div>
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            Online booking systems are incomplete without having bus reservation system apart from flights, hotels and cars. With increase in online bus booking, many bus suppliers have started consolidating the global data for bus seats availability. Bus suppliers offer its contents through APIs and XMLs to increase its partners across the globe. Bus suppliers empower travel agents to allow its customers and sub-agents to directly book bus tickets from their own online booking system.
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Bus Consolidators
                            </div>
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    Travel portal development companies have also come up with bus reservation system and mobile apps, which are user-friendly for the travelers browsing from their smartphones, laptops and tablets. Online bus seat bookings have become a major source of revenue generation for the travel management companies.
                                </ol>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Major Bus aggregateor / Bus Suppliers are:
                            </div>

                            <div className="flex flex-row ">
                                <div className="px-5">
                                    <ol className=" text-paragraph">
                                    • Red Bus <br />
                                    • Ticket Goose <br />
                                    • Travel Yaari <br />
                                    • Abhi bus <br />
                                    • Ticket Agent <br />
                                    • Hermes
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center ml-4 w-[40%] mt-14 md:mt-0">
                        <img src={Banner6} alt="techaag" className="w-full mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default OnlineReservationSystem;
