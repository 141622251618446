import React from "react";
import Banner1 from "../../assets/Service/DigitalTransAuto/banner1.svg";
import Banner2 from "../../assets/Service/DigitalTransAuto/AutomationBanner1.svg";
import StrategyDev from "../../assets/Service/DigitalTransAuto/strategydev.png";
import Automation from "../../assets/Service/DigitalTransAuto/automation.png";
import Implementation from "../../assets/Service/DigitalTransAuto/implementation.png";
import Training from "../../assets/Service/DigitalTransAuto/training.png";
import Continuous from "../../assets/Service/DigitalTransAuto/continuous.png";
import Support from "../../assets/Service/DigitalTransAuto/support.png";
import Flutter from "../../assets/Service/appdev/flutter.svg";
import ReactJs from "../../assets/Service/appdev/reactnative.svg";
import Python from "../../assets/Service/appdev/python.svg";
import Swift from "../../assets/Service/appdev/swift.svg";
import NodeJS from "../../assets/Service/appdev/nodejs.svg";
import AndriodStudio from "../../assets/Service/appdev/andriodstudio.svg";
import Vue from "../../assets/Service/appdev/vue.svg";
import WhyChooseUs from "../../assets/Service/DigitalTransAuto/whyChooseUs.svg";
import ArrowRight from "../../assets/Service/arrowRight.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function transformationautomation() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col mt-20 gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">
                Digital Transformation &{" "}
              </span>
              <span className="font-bold text-seconday"> Automation</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              Having automated critical processes for more than{" "}
              <span className="text-primary font-bold">15 businesses </span>we
              drive efficiency and innovation, helping companies transition
              smoothly to digital-first strategies.
            </div>
          </div>
          <div className="mt-24">
            <img
              src={Banner1}
              alt="techaag"
              className="mx-auto max-w-96 w-full"
            />
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold"> Choose us </span>
              <span className="">
                for <br />
              </span>
              <span className="text-seconday font-bold">
                Digital Transformation & Automation
              </span>
            </div>
            <div>
              <img src={WhyChooseUs} alt="techaag" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold">
                {" "}
                Digital Transformation{" "}
              </span>
              &
              <span className="text-seconday font-bold">
                {" "}
                Automation Process
              </span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row items-center gap-5">
                <div className="p-4 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={StrategyDev} alt="techaag" className="w-10" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Initial Assessment & Strategy Development
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Needs Assessment:
                    </span>{" "}
                    Understanding your business processes and identifying areas
                    for improvement.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Customized Strategy:
                    </span>{" "}
                    Developing a tailored digital transformation strategy that
                    aligns with your business goals.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                  <img src={Implementation} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Technology Selection & Implementation
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Technology Evaluation:
                    </span>{" "}
                    Evaluating and selecting the best technologies to meet your
                    transformation needs.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Seamless Implementation:
                    </span>{" "}
                    Implementing digital solutions with minimal disruption to
                    your operations.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#FFBDA7] to-[#DDB1A2]">
                  <img src={Automation} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Process Automation</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Robotic Process Automation (RPA):
                    </span>{" "}
                    Implementing RPA to automate repetitive tasks and improve
                    efficiency.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Intelligent Workflows:
                    </span>{" "}
                    Designing intelligent workflows that enhance productivity
                    and streamline operations.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#F0423A] to-[#A53732]">
                  <img src={Training} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Training & Change Management
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      User Training:
                    </span>{" "}
                    Providing comprehensive training to ensure your team is
                    equipped to use new technologies.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Change Management:
                    </span>{" "}
                    Assisting in managing the change process to ensure a smooth
                    transition.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                  <img src={Continuous} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Continuous Improvement</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Ongoing Evaluation:
                    </span>{" "}
                    Regularly evaluating the effectiveness of digital solutions
                    and making necessary adjustments.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Scalability Planning:
                    </span>{" "}
                    Planning for future scalability to ensure your digital
                    transformation evolves with your business.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                  <img src={Support} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Support & Maintenance</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Continuous Support:
                    </span>{" "}
                    Offering ongoing support to address any issues and ensure
                    optimal performance.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Maintenance Services:
                    </span>{" "}
                    Providing maintenance services to keep your digital
                    solutions running smoothly.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={StrategyDev} alt="techaag" className="w-10" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Initial Assessment & Strategy Development
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Needs Assessment:
                        </span>{" "}
                        Understanding your business processes and identifying
                        areas for improvement.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Customized Strategy:
                        </span>{" "}
                        Developing a tailored digital transformation strategy
                        that aligns with your business goals.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                      <img
                        src={Implementation}
                        alt="techaag"
                        className="w-16"
                      />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Technology Selection & Implementation
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Technology Evaluation:
                        </span>{" "}
                        Evaluating and selecting the best technologies to meet
                        your transformation needs.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Seamless Implementation:
                        </span>{" "}
                        Implementing digital solutions with minimal disruption
                        to your operations.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#FFBDA7] to-[#DDB1A2]">
                      <img src={Automation} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Process Automation</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Robotic Process Automation (RPA):
                        </span>{" "}
                        Implementing RPA to automate repetitive tasks and
                        improve efficiency.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Intelligent Workflows:
                        </span>{" "}
                        Designing intelligent workflows that enhance
                        productivity and streamline operations.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#F0423A] to-[#A53732]">
                      <img src={Training} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Training & Change Management
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          User Training:
                        </span>{" "}
                        Providing comprehensive training to ensure your team is
                        equipped to use new technologies.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Change Management:
                        </span>{" "}
                        Assisting in managing the change process to ensure a
                        smooth transition.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                      <img src={Continuous} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Continuous Improvement</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Ongoing Evaluation:
                        </span>{" "}
                        Regularly evaluating the effectiveness of digital
                        solutions and making necessary adjustments.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Scalability Planning:
                        </span>{" "}
                        Planning for future scalability to ensure your digital
                        transformation evolves with your business.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                      <img src={Support} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Support & Maintenance</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Continuous Support:
                        </span>{" "}
                        Offering ongoing support to address any issues and
                        ensure optimal performance.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Maintenance Services:
                        </span>{" "}
                        Providing maintenance services to keep your digital
                        solutions running smoothly.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px] font-light mb-5">
          Technology we use for
          <br />
          <span className="font-bold text-primary">
            {" "}
            Digital Transformation &{" "}
          </span>
          <span className="font-bold text-seconday">Automation Process</span>
        </div>
        <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-5 justify-center hidden items-center md:grid">
            {[
              { name: "Flutter", image: Flutter },
              { name: "Python", image: Python },
              { name: "React", image: ReactJs },
              { name: "Swift", image: Swift },
              { name: "Vue", image: Vue },
              { name: "Node.js", image: NodeJS },
              { name: "Android Studio", image: AndriodStudio },
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                  <img src={item.image} alt={item.name} className="w-full" />
                </div>
                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { name: "Flutter", image: Flutter },
                { name: "Python", image: Python },
                { name: "React", image: ReactJs },
                { name: "Swift", image: Swift },
                { name: "Vue", image: Vue },
                { name: "Node.js", image: NodeJS },
                { name: "Android Studio", image: AndriodStudio },
              ].map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-auto h-32">
                    <img src={item.image} alt={item.name} className="w-full" />
                    <p className="text-center mt-2">{item.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:px-5">
            <img
              src={Banner2}
              alt="techaag"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 text-[#EF7F1A] hover:text-blue-900 mt-2 md:flex-row md:items-start md:justify-start md:mt-2">
          <span className="font-semibold text-md text-center md:text-left">
            Check AI & ML Projects
          </span>
          <img src={ArrowRight} alt="techaag" />
        </div>
      </div>
    </div>
  );
}

export default transformationautomation;
