import React from "react";
import AppDevelopment from "../../assets/Service/appdev/appdevbanner.svg";
import Calendar from "../../assets/Service/calendar.svg";
import Design from "../../assets/Service/prototyping.svg";
import Code from "../../assets/Service/design.svg";
import Testing from "../../assets/Service/testing.svg";
import Maintenance from "../../assets/Service/maintenance.svg";
import SEO from "../../assets/Service/seo.svg";
import AppDev from "../../assets/Service/appdev/appdevbanner2.svg";
import Flutter from "../../assets/Service/appdev/flutter.svg";
import ReactJs from "../../assets/Service/appdev/reactnative.svg";
import Python from "../../assets/Service/appdev/python.svg";
import Django from "../../assets/Service/appdev/django.svg";
import Swift from "../../assets/Service/appdev/swift.svg";
import NodeJS from "../../assets/Service/appdev/nodejs.svg";
import AndriodStudio from "../../assets/Service/appdev/andriodstudio.svg";
import Vue from "../../assets/Service/appdev/vue.svg";
import ArrowRight from "../../assets/Service/arrowRight.svg";
import WhyChooseUs from "../../assets/Service/appdev/whyChooseUs.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function appDevelopment() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col mt-20 gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              So You’re Looking For
              <br />
              <span className="font-bold text-primary">App </span>
              <span className="font-bold text-seconday">Development</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              Having developed more than{" "}
              <span className="text-primary font-bold">
                20 custom mobile and web applications
              </span>{" "}
              we excel in delivering scalable, user-friendly apps that align
              with your business goals.
            </div>
            {/* <button className="bg-gradient-to-r from-gradient-3 to-gradient-4 w-fit text-white p-4 rounded-lg">
              Let’s Connect
            </button> */}
          </div>
          <div className="mt-24">
            <img src={AppDevelopment} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold">
                {" "}
                Choose us <br />
              </span>
              <span className="">for </span>
              <span className="text-seconday font-bold">App Development</span>
            </div>
            <div>
              <img src={WhyChooseUs} alt="techaag" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold"> App Development </span>
              <span className="text-seconday font-bold">Process</span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={Calendar} alt="techaag" className="w-10" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Requirement Analysis & Planning
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Initial Consultation:
                    </span>{" "}
                    Understanding your business goals and app requirements.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Project Planning:
                    </span>{" "}
                    Developing a detailed project plan with timelines and
                    milestones.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                  <img src={Design} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Design & Prototyping</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      UI/UX Design:
                    </span>{" "}
                    Creating prototypes and mockups to visualize the app’s
                    design and functionality.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      User Testing:
                    </span>{" "}
                    Conducting user testing to gather feedback and refine the
                    design. Conducting user testing to gather feedback and
                    refine the design.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                  <img src={Code} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Development & Integration</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Agile Development:
                    </span>{" "}
                    Building the app using agile methodologies to ensure
                    flexibility and quick iterations.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      API Integration:
                    </span>{" "}
                    Integrating third-party APIs to enhance app functionality.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                  <img src={Testing} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Testing & Deployment</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Quality Assurance:
                    </span>{" "}
                    Conducting rigorous testing to ensure the app is bug-free
                    and performs well.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      App Store Deployment:
                    </span>{" "}
                    Assisting with app store submissions and ensuring compliance
                    with app store guidelines.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                  <img src={Maintenance} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Support & Maintenance</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Ongoing Support:
                    </span>{" "}
                    Providing post-launch support to address any issues and
                    ensure smooth operation.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Regular Updates:
                    </span>{" "}
                    Releasing regular updates to improve app performance and add
                    new features.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                  <img src={SEO} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Analytics & Optimization</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Performance Monitoring:
                    </span>{" "}
                    Continuously monitoring app performance to identify and
                    resolve any issues.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Data-Driven Improvements:
                    </span>{" "}
                    Using analytics to gather user insights and optimize the app
                    for better user experience and engagement.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={Calendar} alt="techaag" className="w-12" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Requirement Analysis & Planning
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Initial Consultation:
                        </span>{" "}
                        Understanding your business goals and app requirements.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Project Planning:
                        </span>{" "}
                        Developing a detailed project plan with timelines and
                        milestones.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                      <img src={Design} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Design & Prototyping</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          UI/UX Design:
                        </span>{" "}
                        Creating prototypes and mockups to visualize the app’s
                        design and functionality.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          User Testing:
                        </span>{" "}
                        Conducting user testing to gather feedback and refine
                        the design. Conducting user testing to gather feedback
                        and refine the design.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                      <img src={Code} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Development & Integration
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Agile Development:
                        </span>{" "}
                        Building the app using agile methodologies to ensure
                        flexibility and quick iterations.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          API Integration:
                        </span>{" "}
                        Integrating third-party APIs to enhance app
                        functionality.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                      <img src={Testing} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Testing & Deployment</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Quality Assurance:
                        </span>{" "}
                        Conducting rigorous testing to ensure the app is
                        bug-free and performs well.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          App Store Deployment:
                        </span>{" "}
                        Assisting with app store submissions and ensuring
                        compliance with app store guidelines.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                      <img src={Maintenance} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Support & Maintenance</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Ongoing Support:
                        </span>{" "}
                        Providing post-launch support to address any issues and
                        ensure smooth operation.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Regular Updates:
                        </span>{" "}
                        Releasing regular updates to improve app performance and
                        add new features.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                      <img src={SEO} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Analytics & Optimization
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Performance Monitoring:
                        </span>{" "}
                        Continuously monitoring app performance to identify and
                        resolve any issues.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Data-Driven Improvements:
                        </span>{" "}
                        Using analytics to gather user insights and optimize the
                        app for better user experience and engagement.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px]  font-light mb-5">
          Technology we use for
          <br />
          <span className="font-bold text-primary">App </span>
          <span className="font-bold text-seconday">Development</span>
        </div>
        <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-5 justify-center items-center hidden md:grid">
            {[
              { name: "Flutter", image: Flutter },
              { name: "Python", image: Python },
              { name: "React", image: ReactJs },
              { name: "Django", image: Django },
              { name: "Swift", image: Swift },
              { name: "Vue", image: Vue },
              { name: "Node.js", image: NodeJS },
              { name: "Android Studio", image: AndriodStudio },
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                  <img src={item.image} alt={item.name} className="w-full" />
                </div>
                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { name: "Flutter", image: Flutter },
                { name: "Python", image: Python },
                { name: "React", image: ReactJs },
                { name: "Swift", image: Swift },
                { name: "Vue", image: Vue },
                { name: "Node.js", image: NodeJS },
                { name: "Android Studio", image: AndriodStudio },
              ].map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-auto h-32">
                    <img src={item.image} alt={item.name} className="w-full" />
                    <p className="text-center mt-2">{item.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:px-5">
            <img src={AppDev} alt="techaag" className="mx-auto" />
          </div>
        </div>
        <div className="flex flex-row gap-2 text-[#EF7F1A] hover:text-blue-900">
          <span className="font-semibold text-md ">
            Check Mobile Development Projects
          </span>
          <img src={ArrowRight} alt="techaag" />
        </div>
      </div>
    </div>
  );
}

export default appDevelopment;
