/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../assets/logo.svg";

import { RiHeartFill } from "@remixicon/react";
import Location from "../../assets/icon/location.svg";
import Phone from "../../assets/icon/phone.svg";
import Mail from "../../assets/icon/mail.svg";
import { Facebook, Instagram, Twitter, Youtube } from 'lucide-react';
import { NavLink } from "react-router-dom";


function Footer() {
  return (
    <div>
      <footer className="container mx-auto p-2 md:px-8 lg:px-15 divide-y ">
        <div className="grid grid-cols-1  gap-10 justify-between sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col justify-center items-center text-center gap-2 sm:justify-start sm:items-start sm:text-start">
            <img src={logo} alt="techaag"></img>
            <p className="flex flex-col gap-1 items-center md:items-start justify-center md:flex-row">
              <img src={Location} alt="techagg" />
              Unit No 4068 B wings Oberoi Garden Estate Chandivali, Maharashtra
            </p>
            <a href="mailto:info@techaag.com" className="flex gap-2">
              <img src={Mail} alt="techagg" /> info@techaag.com
            </a>
            <a href="tel:+971-507913523" className="flex gap-2">
              <img src={Phone} alt="techagg" />
              +971-507913523
            </a>
            <a href="tel:+91-2265168147" className="flex gap-2">
              <img src={Phone} alt="techagg" />
              +91-2265168147
            </a>
            <div className="flex space-x-3 items-center mt-2 ml-5">
              <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-900 transition-colors cursor-pointer">
                <Facebook size={24} />
              </a>
              <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-pink-500 transition-colors">
                <Instagram size={24} />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-400 transition-colors">
                <Twitter size={24} />
              </a>
              <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-600 transition-colors">
                <Youtube size={24} />
              </a>
            </div>
          </div>
          <div className="mt-0 sm:mt-4 flex flex-col justify-center items-center text-center gap-2 sm:justify-start sm:items-start sm:text-start">
            <h1 className="text-primary font-semibold text-2xl mb-4">
              Useful NavLinks
            </h1>
            <ol>
              <li className="mb-2">
                <NavLink to="/aboutus">About Us</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/portfolio">Portfolio</NavLink>
              </li>
              <li className="mb-2">
                <a href=" https://techaag.hashnode.dev/ " target="_blank" rel="noopener noreferrer">Our Blog</a>
                {/* <NavLink to="/aboutus">Our Blog</NavLink> */}
              </li>
              <li className="mb-2">
                <NavLink to="/privacypolicy">Privacy Policy</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/terms&conditions">Terms & Conditions</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/termsofuse">Terms of Use</NavLink>
              </li>
            </ol>
          </div>
          <div className="mt-0 sm:mt-4 flex flex-col justify-center items-center text-center gap-2 sm:justify-start sm:items-start sm:text-start">
            <h1 className="text-primary font-semibold text-2xl mb-4">
              Services
            </h1>
            <ol>
              <li className="mb-2">
                <NavLink to="/services/dataengineeringdevops">Data Engineering & DevOps</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/cybersecurity&compliance">Cybersecurity & Compliance</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/ai&ml">AI & Machine Learning</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/transformationautomation">Digital Transformation & Automation</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/blockchain">Blockchain & Decentralized Applications</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/iot">IoT & Smart Solutions</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/webdevelopment">Web Development</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/appdevelopment">App Development</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/services/graphicdesigns">Graphic & Ui/Ux Design</NavLink>
              </li>
            </ol>
          </div>
          <div className="mt-0 sm:mt-4 flex flex-col justify-center items-center text-center gap-2 sm:justify-start sm:items-start sm:text-start">
            <h1 className="text-primary font-semibold text-2xl mb-4">
              Product
            </h1>
            <ol>
              <li className="mb-2">
                <NavLink to="/product/travelPortalManagement">B2C Travel Portal</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/product/travelPortalManagement"> B2B Travel Portal </NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/product/whiteLabel">White Label Travel Portal</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/product/onlineReservationSystem">Online Reservation System</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/product/travelSoftwareSolutions">Travel Software</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/product/xmlApiIntegrationSolution">XML API Integration</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/product/travelPortalDevelopment">Travel Portal Development</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/product/globalDistributionSystem">GDS Integration</NavLink>
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col text-center justify-between pt-5 mt-5 md:text-start md:flex-row">
          <NavLink to="/">
            Copyright @ 2024 Advanced Adventures Gear Technologies Pvt. Ltd.
          </NavLink>
          <p className="flex items-center">
            Designed & Developed by TechAAG with
            <RiHeartFill
              size={20} // set custom `width` and `height`
              color="red" // set `fill` color
              className="my-icon" // add custom class name
            />
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
