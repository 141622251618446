import React, { useState } from 'react';
import Banner from "../../assets/Service/AiMl/AIBannner.svg";
import Consultation from "../../assets/Service/AiMl/consultation.png";
import Banner2 from "../../assets/Service/AiMl/AIBannner2.svg";
import Implementation from "../../assets/Service/AiMl/implementation.png";
import ModelDev from "../../assets/Service/AiMl/modeldev.png";
import StrategyDev from "../../assets/Service/AiMl/strategydev.png";
import Continuous from "../../assets/Service/blockchain/continuous.png";
import Training from "../../assets/Service/AiMl/trainingsupport.png";
import Flutter from "../../assets/Service/appdev/flutter.svg";
import ReactJs from "../../assets/Service/appdev/reactnative.svg";
import Python from "../../assets/Service/appdev/python.svg";
import Swift from "../../assets/Service/appdev/swift.svg";
import NodeJS from "../../assets/Service/appdev/nodejs.svg";
import AndriodStudio from "../../assets/Service/appdev/andriodstudio.svg";
import Vue from "../../assets/Service/appdev/vue.svg";
import WhyChooseUs from "../../assets/Service/AiMl/whyChooseUs.svg"
import ArrowRight from "../../assets/Service/arrowRight.svg"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";
import ConnectNow from '../connectnow/ConnectNow';
const AIML = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    return (
        <div>
            <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
                <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
                    <div className="flex flex-col gap-5 mt-20">
                        <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
                            <span className="font-bold text-primary">Artificial Intelligence &</span>
                            <span className="font-bold text-seconday">  Machine Learning</span>
                        </div>
                        <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
                            With over{" "}
                            <span className="text-primary font-bold">10 AI and ML </span>projects successfully implemented, we offer intelligent solutions that elevate decision-making and automate complex tasks.

                        </div>
                    </div>
                    <div className="mt-24">
                        <img src={Banner} alt="techaag" className="mx-auto" />
                    </div>
                </div>
            </div>

            <div className="bg-background">
                <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
                    <div className="grid grid-cols-1 gap-5 justify-center text-center">
                        <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
                            Why Should you
                            <span className="text-primary font-bold"> Choose us  </span>
                            <span className="">for <br /></span>
                            <span className="text-seconday font-bold">AI & Machine Learning</span>
                        </div>
                        <div>
                            <img src={WhyChooseUs} alt="techaag" />
                        </div>

                    </div>
                </div>
            </div>

            <div className="bg-background">
                <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
                    <div className="grid grid-cols-1 gap-5 justify-center text-center">
                        <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
                            Our
                            <span className="text-primary font-bold"> AI & </span>
                            <span className="text-seconday font-bold">Machine Learning Process</span>
                        </div>
                        <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
                            <div className="flex flex-row items-center gap-5">
                                <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                                    <img src={Consultation} alt="techaag" className="w-10" />
                                </div>
                                <div className="text-xl mb-2">
                                    <span className="font-bold">Consultation & Data Assessment</span>
                                    <div className="text-paragraph text-sm">
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Initial Consultation:
                                        </span>{" "}
                                        Understanding your business objectives and data capabilities.
                                        <br />
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Data Assessment:
                                        </span>{" "}
                                        Evaluating the quality and relevance of your data for AI and ML applications.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center gap-5">
                                <div className="p-1 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                                    <img src={StrategyDev} alt="techaag" className="w-14" />
                                </div>
                                <div className="text-xl mb-2">
                                    <span className="font-bold">
                                        AI Strategy Development
                                    </span>
                                    <div className="text-paragraph text-sm">
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Customized Strategy:
                                        </span>{" "}
                                        Developing a tailored AI strategy that aligns with your business goals.
                                        <br />
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Use Case Identification:
                                        </span>{" "}
                                        Identifying the most impactful AI and ML use cases for your organization.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center gap-5">
                                <div className="p-1 rounded-md bg-gradient-to-r from-[#6DE0F6] to-[#65D0E5]">
                                    <img src={ModelDev} alt="techaag" className="w-14" />
                                </div>
                                <div className="text-xl mb-2">
                                    <span className="font-bold">Model Development & Training</span>
                                    <div className="text-paragraph text-sm">
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Algorithm Selection:
                                        </span>{" "}
                                        Choosing the most suitable algorithms for your AI and ML needs.
                                        <br />
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Model Training:
                                        </span>{" "}
                                        Training models on your data to ensure accuracy and reliability.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center gap-5">
                                <div className="p-2 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                                    <img src={Implementation} alt="techaag" className="w-14" />
                                </div>
                                <div className="text-xl mb-2">
                                    <span className="font-bold">
                                        Implementation & Integration
                                    </span>
                                    <div className="text-paragraph text-sm">
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Seamless Integration:
                                        </span>{" "}
                                        Integrating AI and ML models into your existing systems and workflows.
                                        <br />
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Deployment Support:
                                        </span>{" "}
                                        Providing support during the deployment phase to ensure smooth implementation.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center gap-5">
                                <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                                    <img src={Continuous} alt="techaag" className="w-14" />
                                </div>
                                <div className="text-xl mb-2">
                                    <span className="font-bold">Continuous Monitoring & Optimization</span>
                                    <div className="text-paragraph text-sm">
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Performance Monitoring:
                                        </span>{" "}
                                        Continuously monitoring the performance of AI and ML models to ensure optimal results.
                                        <br />
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Model Optimization:
                                        </span>{" "}
                                        Regularly updating and optimizing models based on new data and insights.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row  items-center gap-5">
                                <div className="p-2 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                                    <img src={Training} alt="techaag" className="w-16" />
                                </div>
                                <div className="text-xl mb-2">
                                    <span className="font-bold">Training & Support</span>
                                    <div className="text-paragraph text-sm">
                                        <span className="text-primary font-bold">
                                            {" "}
                                            User Training:
                                        </span>{" "}
                                        Providing training sessions to ensure your team can effectively use AI and ML solutions.
                                        <br />
                                        <span className="text-primary font-bold">
                                            {" "}
                                            Ongoing Support:
                                        </span>{" "}
                                        Offering ongoing support to address any issues and enhance the functionality of AI and ML applications.
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Swiper Slider */}
                        <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
                            <Swiper
                                spaceBetween={10}
                                navigation={false}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                                className="mySwiper4"
                            >
                                <SwiperSlide>
                                    <div className="flex flex-col items-center gap-5">
                                        <div className="p-1 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                                            <img src={Consultation} alt="techaag" className="w-10" />
                                        </div>
                                        <div className="text-xl mb-2">
                                            <span className="font-bold">Consultation & Data Assessment</span>
                                            <div className="text-paragraph text-sm">
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Initial Consultation:
                                                </span>{" "}
                                                Understanding your business objectives and data capabilities.
                                                <br />
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Data Assessment:
                                                </span>{" "}
                                                Evaluating the quality and relevance of your data for AI and ML applications.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="flex flex-col items-center gap-5">
                                        <div className="p-1 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                                            <img src={StrategyDev} alt="techaag" className="w-14" />
                                        </div>
                                        <div className="text-xl mb-2">
                                            <span className="font-bold">
                                                AI Strategy Development
                                            </span>
                                            <div className="text-paragraph text-sm">
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Customized Strategy:
                                                </span>{" "}
                                                Developing a tailored AI strategy that aligns with your business goals.
                                                <br />
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Use Case Identification:
                                                </span>{" "}
                                                Identifying the most impactful AI and ML use cases for your organization.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="flex flex-col items-center gap-5">
                                        <div className="p-1 rounded-md bg-gradient-to-r from-[#6DE0F6] to-[#65D0E5]">
                                            <img src={ModelDev} alt="techaag" className="w-14" />
                                        </div>
                                        <div className="text-xl mb-2">
                                            <span className="font-bold">Model Development & Training</span>
                                            <div className="text-paragraph text-sm">
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Algorithm Selection:
                                                </span>{" "}
                                                Choosing the most suitable algorithms for your AI and ML needs.
                                                <br />
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Model Training:
                                                </span>{" "}
                                                Training models on your data to ensure accuracy and reliability.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="flex flex-col items-center gap-5">
                                        <div className="p-2 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                                            <img src={Implementation} alt="techaag" className="w-14" />
                                        </div>
                                        <div className="text-xl mb-2">
                                            <span className="font-bold">
                                                Implementation & Integration
                                            </span>
                                            <div className="text-paragraph text-sm">
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Seamless Integration:
                                                </span>{" "}
                                                Integrating AI and ML models into your existing systems and workflows.
                                                <br />
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Deployment Support:
                                                </span>{" "}
                                                Providing support during the deployment phase to ensure smooth implementation.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="flex flex-col items-center gap-5">
                                        <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                                            <img src={Continuous} alt="techaag" className="w-14" />
                                        </div>
                                        <div className="text-xl mb-2">
                                            <span className="font-bold">Continuous Monitoring & Optimization</span>
                                            <div className="text-paragraph text-sm">
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Performance Monitoring:
                                                </span>{" "}
                                                Continuously monitoring the performance of AI and ML models to ensure optimal results.
                                                <br />
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Model Optimization:
                                                </span>{" "}
                                                Regularly updating and optimizing models based on new data and insights.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="flex flex-col items-center gap-5">
                                        <div className="rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                                            <img src={Training} alt="techaag" className="w-11" />
                                        </div>
                                        <div className="text-xl mb-2">
                                            <span className="font-bold">Training & Support</span>
                                            <div className="text-paragraph text-sm">
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    User Training:
                                                </span>{" "}
                                                Providing training sessions to ensure your team can effectively use AI and ML solutions.
                                                <br />
                                                <span className="text-primary font-bold">
                                                    {" "}
                                                    Ongoing Support:
                                                </span>{" "}
                                                Offering ongoing support to address any issues and enhance the functionality of AI and ML applications.
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        {/* Swiper Slider end */}
                    </div>
                </div>
            </div>

            <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
                <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px]  font-light mb-5">
                    Technology we use for
                    <br />
                    <span className="font-bold text-primary"> AI & </span>
                    <span className="font-bold text-seconday">Machine Learning</span>
                </div>
                <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-5 justify-center hidden items-center md:grid">
                        {[
                            { name: 'Flutter', image: Flutter },
                            { name: 'Python', image: Python },
                            { name: 'React', image: ReactJs },
                            { name: 'Swift', image: Swift },
                            { name: 'Vue', image: Vue },
                            { name: 'Node.js', image: NodeJS },
                            { name: 'Android Studio', image: AndriodStudio },
                        ].map((item, index) => (
                            <div key={index} className="group relative">
                                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                                    <img src={item.image} alt={item.name} className="w-full" />
                                </div>
                                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    {item.name}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-row gap-5 lg:px-5 md:hidden">
                        <Swiper
                            spaceBetween={10}
                            navigation={false}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                            className="mySwiper4"
                        >
                            {[
                                { name: 'Flutter', image: Flutter },
                                { name: 'Python', image: Python },
                                { name: 'React', image: ReactJs },
                                { name: 'Swift', image: Swift },
                                { name: 'Vue', image: Vue },
                                { name: 'Node.js', image: NodeJS },
                                { name: 'Android Studio', image: AndriodStudio },
                            ].map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="w-auto h-32">
                                        <img src={item.image} alt={item.name} className="w-full" />
                                        <p className="text-center mt-2">{item.name}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="md:px-5">
                        <img
                            src={Banner2}
                            alt="techaag"
                            className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto"
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center gap-2 text-[#EF7F1A] hover:text-blue-900 mt-2 md:flex-row md:items-start md:justify-start md:mt-2">
                    {/* <span className="font-semibold text-md text-center md:text-left">Check AI & ML Projects</span>
                    <img src={ArrowRight} alt="techaag" /> */}
                </div>


            </div>

            <div className="container mx-auto gap-5 relative p-5 md: mb-10 py-20 lg:px-15">
                <div className="grid grid-cols-1 gap-5 justify-between items-center md:grid-cols-2">
                    <div className="font-bold text-xl text-center w-full md:text-left md:w-3/4 md:text-3xl">
                        Choose us for <br />
                        AI & Machine Learning
                    </div>
                    <div className="flex justify-center md:justify-end">
                        <button onClick={openModal} className="bg-primary w-fit text-white py-5 px-16 rounded-lg ">
                            Let’s Connect
                        </button>
                        <ConnectNow isOpen={isModalOpen} onClose={closeModal} />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AIML
