import React from "react";
import Banner from "../../assets/privacypolicy/terms&conditionbanner.png";

function termsofuse() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5  mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Terms </span> of
              <span className="font-bold text-seconday"> Use</span>
            </div>
            <div className="text-paragraph font-normal text-base text-justify md:mb-10">
              Welcome to TechAAG. By accessing or using our services, you agree
              to comply with these Terms and Conditions. If you do not agree,
              please do not use our services.
            </div>
          </div>
          <div>
            <img src={Banner} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            1. Acceptance of Terms
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              By accessing or using our Services, you agree to be bound by these
              Terms of Use and our Privacy Policy. If you do not agree to these
              terms, you must not use our Services.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            2. Legal Contract
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              When you access or use our Services, you enter into a legal
              contract with TechAAG. By using our Services, you agree to these
              Terms of Use, our Privacy Policy, and any additional terms
              applicable to specific services you utilize.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            3. Services Provided
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              TechAAG provides travel technology solutions, including but not
              limited to, software, web applications, and mobile applications
              designed to enhance the operations of travel agencies, tour
              operators, and other travel-related businesses.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            4. Intellectual Property
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              All content, software, and technology on our Services are owned by
              or licensed to TechAAG and are protected by intellectual property
              laws. You may not copy, distribute, transmit, display, perform,
              reproduce, publish, license, create derivative works from,
              transfer, or sell any information, software, products, or services
              obtained from our Services without our prior written consent.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            5. User Conduct
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              You agree to use our Services only for lawful purposes and in a
              way that does not infringe the rights of, restrict, or inhibit
              anyone else's use and enjoyment of the Services. Prohibited
              behavior includes harassing or causing distress or inconvenience
              to any other user, transmitting obscene or offensive content, or
              disrupting the normal flow of dialogue within our Services.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            6. Account Security
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              You are responsible for maintaining the confidentiality of your
              account information, including your password, and for all
              activities that occur under your account. You agree to notify
              TechAAG immediately of any unauthorized use of your account or any
              other breach of security. TechAAG will not be liable for any loss
              that you may incur as a result of someone else using your password
              or account, either with or without your knowledge.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            7. Privacy
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              Your use of our Services is also governed by our Privacy Policy,
              which addresses our collection, use, and storage of your personal
              information. By using our Services, you consent to the collection
              and use of information as described in our Privacy Policy.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            8. Modifications to Services and Terms
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              TechAAG reserves the right to modify or discontinue, temporarily
              or permanently, the Services (or any part thereof) with or without
              notice. We also reserve the right to change these Terms of Use at
              any time. Your continued use of the Services after such changes
              have been made will constitute your acceptance of the new terms.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            9. Termination
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              We may terminate or suspend your access to the Services at any
              time, without prior notice or liability, for any reason, including
              if you breach these Terms of Use. Upon termination, your right to
              use the Services will immediately cease.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            10. Limitation of Liability
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              To the fullest extent permitted by law, TechAAG, its affiliates,
              officers, directors, employees, and agents shall not be liable for
              any indirect, incidental, special, consequential, or punitive
              damages, or any loss of profits or revenues, whether incurred
              directly or indirectly, or any loss of data, use, goodwill, or
              other intangible losses, resulting from (a) your use or inability
              to use the Services; (b) any unauthorized access to or use of our
              servers and/or any personal information stored therein; (c) any
              interruption or cessation of transmission to or from the Services;
              (d) any bugs, viruses, trojan horses, or the like that may be
              transmitted to or through our Services by any third party; (e) any
              errors or omissions in any content or for any loss or damage
              incurred as a result of the use of any content posted, emailed,
              transmitted, or otherwise made available through the Services;
              and/or (f) the defamatory, offensive, or illegal conduct of any
              third party.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            11. Governing Law
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              These Terms of Use shall be governed by and construed in
              accordance with the laws of the jurisdiction in which TechAAG is
              established, without regard to its conflict of law principles. You
              agree to submit to the personal and exclusive jurisdiction of the
              courts located within that jurisdiction.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
            12) Contact Us
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              If you have any questions about this Privacy Policy, please
              contact us at{" "}
              <a
                href="mailto:support@techaag.com"
                className="text-primary font-bold hover:text-seconday"
              >
                support@techaag.com
              </a>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default termsofuse;
