import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com'; 
import "./letsconnect.css";

const LetsConnect = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        country: "",
        requirement: "",
        numberOfReason: "",
        technology: "",
        message: ""
    });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling
        }

        return () => {
            document.body.style.overflow = 'auto'; // Reset when component unmounts
        };
    }, [isOpen]);

    if (!isOpen) return null;

    // Function to validate form data
    const validateForm = () => {
        const errors = {};

        if (!formData.firstName) errors.firstName = "First name is required";
        if (!formData.lastName) errors.lastName = "Last name is required";
        if (!formData.email) errors.email = "Email is required";
        if (!formData.mobile) errors.mobile = "Mobile number is required";
        if (!formData.country) errors.country = "Country is required";
        if (!formData.requirement) errors.requirement = "Requirement is required";
        if (!formData.numberOfReason) errors.numberOfReason = "Number of reasons is required";
        if (!formData.technology) errors.technology = "Technology is required";
        if (!formData.message) errors.message = "Message is required";

        return errors;
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // Send form data via EmailJS
        emailjs
            .send(
                "service_2wqn3ze", 
                "template_4chyix7", 
                {
                    ...formData,
                },
                "vF2GvwR9Or4yS-igh" 
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Form successfully submitted! We'll be in touch.");
                    onClose(); 
                },
                (error) => {
                    console.log(error.text);
                    alert("There was an error submitting the form.");
                }
            );
    };

    // Function to handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" }); // Clear error on change
    };

    return (
        <div className='letsconnect-container' onClick={onClose}>
            <div className='inside-container' onClick={(e) => e.stopPropagation()}>
                <div className='cross' onClick={onClose}>
                    <i className="fa-solid fa-xmark"></i>
                </div>

                <h1 className='letsconnect-heading'>Let's <span>Connect</span></h1>

                <form onSubmit={handleSubmit}>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>First Name<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.firstName && <span className="error">{formErrors.firstName}</span>}
                        </div>
                        <div className='input'>
                            <p>Last Name<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.lastName && <span className="error">{formErrors.lastName}</span>}
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>Email<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="email"
                                type="email"
                                placeholder='abc@gmail.com'
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.email && <span className="error">{formErrors.email}</span>}
                        </div>
                        <div className='input'>
                            <p>Mobile<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="mobile"
                                type="tel"
                                placeholder='1020304050'
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.mobile && <span className="error">{formErrors.mobile}</span>}
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>Country<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.country && <span className="error">{formErrors.country}</span>}
                        </div>
                        <div className='input'>
                            <p>Requirement<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="requirement"
                                value={formData.requirement}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.requirement && <span className="error">{formErrors.requirement}</span>}
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input'>
                            <p>Number of Reasons<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="numberOfReason"
                                placeholder='No of Reasons'
                                value={formData.numberOfReason}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.numberOfReason && <span className="error">{formErrors.numberOfReason}</span>}
                        </div>
                        <div className='input'>
                            <p>Technology<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="technology"
                                value={formData.technology}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.technology && <span className="error">{formErrors.technology}</span>}
                        </div>
                    </div>
                    <div className='inside-input'>
                        <div className='input message'>
                            <p>Message<span><i className="fa-solid fa-star-of-life"></i></span></p>
                            <input
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.message && <span className="error">{formErrors.message}</span>}
                        </div>
                    </div>

                    <button type="submit" className='submit'>Submit</button>
                </form>
            </div>
        </div>
    );
};

export default LetsConnect;
