import React from "react";
import Banner from "../../assets/privacypolicy/terms&conditionbanner.png";

function termscondition() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5  mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Terms </span> &
              <span className="font-bold text-seconday"> Conditions</span>
            </div>
            <div className="text-paragraph font-normal text-base text-justify md:mb-10">
              Welcome to TechAAG. By accessing or using our services, you agree
              to comply with these Terms and Conditions. If you do not agree,
              please do not use our services.
            </div>
          </div>
          <div>
            <img src={Banner} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           1) Use of Services
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              <li>
                <span className="font-bold text-black">Eligibility:</span> You
                must be at least 18 years old to use our services.
              </li>
              <li>
                <span className="font-bold text-black">
                  {" "}
                  Account Responsibility:{" "}
                </span>{" "}
                You are responsible for maintaining the confidentiality of your
                account information and for all activities under your account.
              </li>
              <li>
                <span className="font-bold text-black">
                  Prohibited Activities:
                </span>{" "}
                You agree not to use our services for any unlawful or prohibited
                activities.
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           2) Intellectual Property
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              <li>
                <span className="font-bold text-black">Ownership:</span> All
                content and materials on our website are owned by TechAAG or our
                licensors.
              </li>
              <li>
                <span className="font-bold text-black">License:</span> You are
                granted a limited, non-exclusive license to access and use our
                services for personal use only.
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           3) Disclaimers and Limitation of Liability
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              <li>
                <span className="font-bold text-black">No Warranty:</span> Our
                services are provided "as is" without warranties of any kind.
              </li>
              <li>
                <span className="font-bold text-black">
                  Limitation of Liability:
                </span>{" "}
                TechAAG will not be liable for any damages arising from your use
                of our services.
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           4) Indemnification
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              You agree to indemnify and hold TechAAG harmless from any claims,
              damages, or expenses arising from your use of our services.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           5) Termination
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              We may terminate or suspend your access to our services at any
              time, without notice, for any reason.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           6) Governing Law
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              These Terms and Conditions are governed by the laws of the
              jurisdiction in which TechAAG is located, without regard to its
              conflict of law principles.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           7) Changes to These Terms
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              We may update these Terms and Conditions from time to time. We
              will notify you of any changes by posting the new Terms and
              Conditions on our website.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           8) Contact Us
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              If you have any questions about this Terms & Conditions, please
              contact us at{" "}
              <a
                href="mailto:support@techaag.com"
                className="text-primary font-bold hover:text-seconday"
              >
                support@techaag.com
              </a>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default termscondition;
