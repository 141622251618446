import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import Footer from "./components/footer/index";
// import Home from "./pages/home/NewIndex.js"

import Aboutus from "./pages/aboutus/Aboutus.js";
import Polygon from "./assets/Background/Polygon.svg";
import Polygon3 from "./assets/Background/Polygon-3.svg";
import Portfolio from "./pages/portfolio";
import Contactus from "./pages/contactus";
import WebDevelopment from "./pages/services/webdevelopment.jsx";
import AppDevelopment from "./pages/services/appdevelopment.jsx";
import DataEngineeringDevops from "./pages/services/dataengineeringdevops.jsx";
import CyberSecurity from "./pages/services/cybersecurity.jsx";
import Blockchain from "./pages/services/blockChain.jsx";
import AIML from "./pages/services/AIML.js";
import Iot from "./pages/services/iot.jsx";
import Graphicdesigns from "./pages/services/graphicdesigns.jsx";
import TransformationAuto from "./pages/services/transformationautomation.jsx";
import Careers from "./pages/careers/Careers.js";
import Privacy from "./pages/privacypolicy/index.jsx";
import TermsCondition from "./pages/terms&condition/index.jsx";
import Termsofuse from "./pages/termsofuse/index.jsx";
import WhiteLabel from "./pages/product/whiteLabel.jsx";
import TravelPortalManagement from "./pages/product/travelPortalManagement.jsx";
import OnlineReservationSystem from "./pages/product/onlineReservationSystem.jsx";
import GlobalDistributionSystem from "./pages/product/globalDistributionSystem.jsx";
import XMLApiIntegrationSolution from "./pages/product/xmlApiIntegrationSolution.jsx";
import NewTravelSoftware from "./pages/product/NewTravelSoftware.js";
import TravelPortalDevelopment from "./pages/product/travelPortalDevelopment.jsx";
import NewHeader from "./components/NewHeader.js/NewHeader.js";
import Newind from "./pages/home/Newind.js";

// New component to handle loading bar logic
function LoadingBarWrapper({ children, setLoading }) {
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    ref.current.continuousStart(0);
    setLoading(true); // Show white screen while loading
    const timer = setTimeout(() => {
      ref.current.complete();
      setLoading(false); // Show content after loading is complete
    }, 500);

    return () => clearTimeout(timer);
  }, [location.pathname, setLoading]);

  return (
    <>
      <LoadingBar color="#f11946" ref={ref} height={3} />
      {children}
    </>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <div
      style={{
        backgroundImage: loading ? "none" : `url(${Polygon}), url(${Polygon3})`,
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "top left, bottom ",
        backgroundColor: loading ? "#eee" : "transparent", // Set white background when loading
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <BrowserRouter>
        <LoadingBarWrapper setLoading={setLoading}>
          {!loading && (
            <>
              <NewHeader/>
              <Routes>
                {/* <Route path="*" element={<NotFound404 />} /> */}
                <Route path="/" element={<Newind />} />
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contactus" element={<Contactus />} />
                <Route path="/services/webdevelopment" element={<WebDevelopment />} />
                <Route path="/services/appdevelopment" element={<AppDevelopment />} />
                <Route path="/services/dataengineeringdevops" element={<DataEngineeringDevops />} />
                <Route path="/services/cybersecurity&compliance" element={<CyberSecurity />} />
                <Route path="/services/blockchain" element={<Blockchain />} />
                <Route path="/services/Ai&ml" element={<AIML />} />
                <Route path="/services/transformationautomation" element={<TransformationAuto />} />
                <Route path="/services/iot" element={<Iot />} />
                <Route path="/services/graphicdesigns" element={<Graphicdesigns />} />
                <Route path="/product/travelPortalManagement" element={<TravelPortalManagement />} />
                <Route path="/product/whiteLabel" element={<WhiteLabel />} />
                <Route path="/product/onlineReservationSystem" element={<OnlineReservationSystem />} />
                <Route path="/product/globalDistributionSystem" element={<GlobalDistributionSystem />} />
                <Route path="/product/xmlApiIntegrationSolution" element={<XMLApiIntegrationSolution />} />
                <Route path="/product/travelSoftwareSolutions" element={<NewTravelSoftware />} />
                <Route path="/product/travelPortalDevelopment" element={<TravelPortalDevelopment />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/privacypolicy" element={<Privacy />} />
                <Route path="/terms&conditions" element={<TermsCondition />} />
                <Route path="/termsofuse" element={<Termsofuse />} />
              </Routes>
              <Footer />
            </>
          )}
        </LoadingBarWrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
