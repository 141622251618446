import React from "react";
import WebDevelopment from "../../assets/Service/webdevelopment.png";
import WhyChoose from "../../assets/Service/webdevelopment/whychoose.svg";
import Calendar from "../../assets/Service/calendar.svg";
import Design from "../../assets/Service/design.svg";
import Testing from "../../assets/Service/testing.svg";
import Maintenance from "../../assets/Service/maintenance.svg";
import ContentCreation from "../../assets/Service/content-marketing.svg";
import SEO from "../../assets/Service/seo.svg";
import WebDev from "../../assets/Service/webdevelopment/webdev.png";
import HTML from "../../assets/Service/webdevelopment/html.svg";
import CSS from "../../assets/Service/webdevelopment/css.svg";
import Javascript from "../../assets/Service/webdevelopment/javacript.svg";
import AngularJs from "../../assets/Service/webdevelopment/angularjs.svg";
import Nextjs from "../../assets/Service/webdevelopment/nextjs.svg";
import Vue from "../../assets/Service/webdevelopment/vue.svg";
import Jquery from "../../assets/Service/webdevelopment/jquery.svg";
import SASS from "../../assets/Service/webdevelopment/sass.svg";
import Bootstrap from "../../assets/Service/webdevelopment/bootstrap.svg";
import Nodejs from "../../assets/Service/webdevelopment/nodejs.svg";
import AWS from "../../assets/Service/webdevelopment/aws.svg";
import Python from "../../assets/Service/webdevelopment/python.svg";
import Mysql from "../../assets/Service/webdevelopment/mysql.svg";
import MongoDB from "../../assets/Service/webdevelopment/mongoDb.svg";
import Firebase from "../../assets/Service/webdevelopment/firebase.svg";
import Laravel from "../../assets/Service/webdevelopment/laravel.svg";
import Go from "../../assets/Service/webdevelopment/go.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function webdevelopment() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col mt-20 gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              So You’re Looking For
              <br />
              <span className="font-bold text-primary">Web </span>
              <span className="font-bold text-seconday">Development</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              With over{" "}
              <span className="text-primary font-bold">15 successful</span> web
              development projects under our belt, we pride ourselves on
              delivering exceptional digital solutions that drive results.
            </div>
            {/* <button className="bg-gradient-to-r from-gradient-3 to-gradient-4 w-fit text-white p-4 rounded-lg">
              Let’s Connect
            </button> */}
          </div>
          <div className="mt-24">
            <img src={WebDevelopment} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold"> Choose us </span>for <br />
              <span className="text-seconday font-bold"> Web Development </span>
            </div>
            <div>
              <img src={WhyChoose} alt="techaag" />
            </div>

          </div>
        </div>
      </div>
      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold"> Web Development </span>
              <span className="text-seconday font-bold">Process</span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={Calendar} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Consultation & Planning</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Understanding Your Needs:
                    </span>{" "}
                    Thorough consultation to understand your business objectives
                    and requirements.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Project Planning:
                    </span>{" "}
                    Developing a detailed project plan that outlines the scope,
                    timeline, and milestones.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                  <img src={Design} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Design & Development</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      UI/UX Design:
                    </span>{" "}
                    Creating wireframes and prototypes to visualize the design
                    and functionality.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Front-end & Back-end Development:
                    </span>{" "}
                    Building the website using the latest technologies and best
                    practices.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                  <img src={Testing} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Testing & Launch</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Quality Assurance:
                    </span>{" "}
                    Conducting thorough testing to ensure the website is
                    bug-free and performs well.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Launch Support:
                    </span>{" "}
                    Providing support during the launch phase to ensure a smooth
                    go-live process.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                  <img src={Maintenance} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Maintenance & Support</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Ongoing Maintenance:
                    </span>{" "}
                    Offering maintenance WebDevelopments to keep your website
                    up-to-date and secure.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Continuous Improvement:
                    </span>{" "}
                    Regularly updating and optimizing the website based on user
                    feedback and new trends.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={ContentCreation} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Content Creation & Management
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Content Strategy:
                    </span>{" "}
                    Developing a content strategy that aligns with your brand
                    and engages your audience.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Content Management System (CMS) Integration:
                    </span>{" "}
                    Implementing a CMS to allow easy content updates and
                    management.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#D800FB] to-[#751086]">
                  <img src={SEO} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    SEO & Performance Optimization
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Search Engine Optimization (SEO):
                    </span>{" "}
                    Implementing SEO best practices to improve your website's
                    visibility in search engines.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Performance Optimization:
                    </span>{" "}
                    Ensuring the website loads quickly and performs efficiently.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={Calendar} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Consultation & Planning</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Understanding Your Needs:
                        </span>{" "}
                        Thorough consultation to understand your business
                        objectives and requirements.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Project Planning:
                        </span>{" "}
                        Developing a detailed project plan that outlines the
                        scope, timeline, and milestones.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                      <img src={Design} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Design & Development</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          UI/UX Design:
                        </span>{" "}
                        Creating wireframes and prototypes to visualize the
                        design and functionality.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Front-end & Back-end Development:
                        </span>{" "}
                        Building the website using the latest technologies and
                        best practices.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                      <img src={Testing} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Testing & Launch</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Quality Assurance:
                        </span>{" "}
                        Conducting thorough testing to ensure the website is
                        bug-free and performs well.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Launch Support:
                        </span>{" "}
                        Providing support during the launch phase to ensure a
                        smooth go-live process.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                      <img src={Maintenance} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Maintenance & Support</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Ongoing Maintenance:
                        </span>{" "}
                        Offering maintenance services to keep your website
                        up-to-date and secure.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Continuous Improvement:
                        </span>{" "}
                        Regularly updating and optimizing the website based on
                        user feedback and new trends.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="px-1 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img
                        src={ContentCreation}
                        alt="techaag"
                        className="w-12"
                      />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Content Creation & Management
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Content Strategy:
                        </span>{" "}
                        Developing a content strategy that aligns with your
                        brand and engages your audience.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Content Management System (CMS) Integration:
                        </span>{" "}
                        Implementing a CMS to allow easy content updates and
                        management.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#D800FB] to-[#751086]">
                      <img src={SEO} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        SEO & Performance Optimization
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Search Engine Optimization (SEO):
                        </span>{" "}
                        Implementing SEO best practices to improve your
                        website's visibility in search engines.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Performance Optimization:
                        </span>{" "}
                        Ensuring the website loads quickly and performs
                        efficiently.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="grid grid-cols-1 gap-10 justify-between">
          <div className="text-[32px] leading-[40px] sm:text-[35px] sm:leading-[45px] font-light">
            Technology we use for
            <br />
            <span className="font-bold text-primary">Frontend </span>
            <span className="font-bold text-secondary">Development</span>
          </div>

          <div className="flex-row gap-5 lg:px-5 hidden md:flex">
            {[
              { src: HTML, title: "HTML" },
              { src: CSS, title: "CSS" },
              { src: Javascript, title: "JavaScript" },
              { src: Jquery, title: "jQuery" },
              { src: AngularJs, title: "AngularJS" },
              { src: Vue, title: "Vue" },
              { src: Nextjs, title: "Next.js" },
              { src: SASS, title: "SASS" },
              { src: Bootstrap, title: "Bootstrap" },
            ].map((tech, index) => (
              <div
                key={index}
                className="p-4 shadow-2xl rounded-lg max-w-28 flex flex-col items-center transition-transform transform hover:-translate-y-2 relative group"
              >
                <img src={tech.src} alt={tech.title} className="w-full" />
                <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 group-hover:mt-2 transition-opacity text-center">
                  {tech.title}
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { src: HTML, title: "HTML" },
                { src: CSS, title: "CSS" },
                { src: Javascript, title: "JavaScript" },
                { src: Jquery, title: "jQuery" },
                { src: AngularJs, title: "AngularJS" },
                { src: Vue, title: "Vue" },
                { src: Nextjs, title: "Next.js" },
                { src: SASS, title: "SASS" },
                { src: Bootstrap, title: "Bootstrap" },
              ].map((tech, index) => (
                <SwiperSlide key={index}>
                  <div className="flex flex-col items-center transition-transform transform hover:-translate-y-2 relative group">
                    <div className="w-auto h-32">
                      <img src={tech.src} alt={tech.title} className="w-full" />
                    </div>

                    <div className="mt-2 transition-opacity text-center">
                      {tech.title}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="flex flex-col gap-5 mt-10 md:px-5">
            <img src={WebDev} alt="techaag" className="max-w-[80%] mx-auto" />
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-10">
        <div className="grid grid-cols-1 gap-10 justify-between">
          <div className="text-[32px] leading-[40px] sm:text-[35px] sm:leading-[45px] font-light">
            Technology we use for
            <br />
            <span className="font-bold text-primary">Backend </span>
            <span className="font-bold text-secondary">Development</span>
          </div>

          <div className="hidden flex-row gap-5 lg:px-5 md:flex">
            {[
              { src: Nodejs, title: "Node.js" },
              { src: AWS, title: "AWS" },
              { src: Python, title: "Python" },
              { src: Mysql, title: "MySQL" },
              { src: MongoDB, title: "MongoDB" },
              { src: Firebase, title: "Firebase" },
              { src: Laravel, title: "Laravel" },
              { src: Go, title: "Go" },
            ].map((tech, index) => (
              <div
                key={index}
                className="p-4 shadow-2xl rounded-lg max-w-28 flex flex-col items-center transition-transform transform hover:-translate-y-2 relative group"
              >
                <img src={tech.src} alt={tech.title} className="w-full" />
                <div className="absolute text-gray-600 bottom-0 opacity-0 group-hover:opacity-100 group-hover:mt-2 transition-opacity text-center">
                  {tech.title}
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper3"
            >
              {[
                { src: Nodejs, title: "Node.js" },
                { src: AWS, title: "AWS" },
                { src: Python, title: "Python" },
                { src: Mysql, title: "MySQL" },
                { src: MongoDB, title: "MongoDB" },
                { src: Firebase, title: "Firebase" },
                { src: Laravel, title: "Laravel" },
                { src: Go, title: "Go" },
              ].map((tech, index) => (
                <SwiperSlide key={index}>
                  <div className="flex flex-col items-center transition-transform transform hover:-translate-y-2 relative group">
                    <div className="w-auto h-32">
                      <img src={tech.src} alt={tech.title} className="w-full" />
                    </div>

                    <div className="mt-2 transition-opacity text-center">
                      {tech.title}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>


      <div className="container mx-auto gap-5 relative p-5 md: mb-10 py-20 lg:px-15">
        <div className="grid grid-cols-1 gap-5 justify-between items-center md:grid-cols-2">
          <div className="font-bold text-xl text-center w-full md:text-left md:w-3/4 md:text-3xl">
            Choose us for  <br />
            Web Development
          </div>
          <div className="flex justify-center md:justify-end">
          </div>
        </div>
      </div>

    </div>
  );
}

export default webdevelopment;
