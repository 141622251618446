import React from "react";
import IOTbanner1 from "../../assets/Service/IOT&Smart/IOTbanner1.svg";
import IOTbanner2 from "../../assets/Service/IOT&Smart/IOTbanner2.svg";
import IOTWhyHire from "../../assets/Service/IOT&Smart/IOTWhyHire.svg";
import Target from "../../assets/Service/IOT&Smart/target.svg";
import secureShield from "../../assets/Service/IOT&Smart/secureShield.svg";
import AnalyticsPresentation from "../../assets/Service/IOT&Smart/analyticsPresentation.svg";
import True from "../../assets/Service/IOT&Smart/true.svg";
import Calendar from "../../assets/Service/calendar.svg";
import Design from "../../assets/Service/design.svg";
import Vue from "../../assets/Service/webdevelopment/vue.svg";
import Flutter from "../../assets/Service/appdev/flutter.svg";
import ReactJs from "../../assets/Service/appdev/reactnative.svg";
import Python from "../../assets/Service/appdev/python.svg";
import Swift from "../../assets/Service/appdev/swift.svg";
import NodeJS from "../../assets/Service/appdev/nodejs.svg";
import AndriodStudio from "../../assets/Service/appdev/andriodstudio.svg";
import ArrowRight from "../../assets/Service/arrowRight.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function Iot() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5 mt-10">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Internet of Things</span>
              <span>
                {" "}
                & <br />
              </span>
              <span className="font-bold text-seconday">Smart Solutions</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              We've successfully connected over{" "}
              <span className="text-primary font-bold">
                50 devices for smart solutions{" "}
              </span>
              providing real-time insights and optimizing operations through
              advanced IoT technology.
            </div>
          </div>
          <div className="mt-20">
            <img src={IOTbanner1} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold"> Hire us </span>
              for <br />
              <span className="text-seconday font-bold">
                IoT & Smart Solutions
              </span>
            </div>
            <div>
              <img src={IOTWhyHire} alt="techaag" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold"> Iot </span>&
              <span className="text-seconday font-bold"> Smart Solutions</span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={Calendar} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Consultation & Needs Assessment
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Initial Consultation:
                    </span>{" "}
                    Understanding your business objectives and specific needs
                    for IoT solutions.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Customized Strategy:
                    </span>{" "}
                    Developing a tailored IoT strategy that aligns with your
                    business goals.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                  <img src={Design} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Solution Design & Development
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      IoT Architecture Design:
                    </span>{" "}
                    Designing a scalable and secure IoT architecture to meet
                    your needs.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Custom Development:
                    </span>{" "}
                    Developing customized IoT solutions that integrate
                    seamlessly with your existing systems.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                  <img src={Target} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Implementation & Integration
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Seamless Integration:
                    </span>{" "}
                    Ensuring smooth integration of IoT solutions with your
                    existing infrastructure.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Deployment Support:
                    </span>{" "}
                    Providing support during the deployment phase to ensure
                    successful implementation.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                  <img
                    src={AnalyticsPresentation}
                    alt="techaag"
                    className="w-16"
                  />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Data Management & Analytics</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Data Collection:
                    </span>{" "}
                    Implementing systems for real-time data collection and
                    exchange.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Advanced Analytics:
                    </span>{" "}
                    Utilizing advanced analytics to generate actionable insights
                    from IoT data.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={True} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Continuous Monitoring & Optimization
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Performance Monitoring:
                    </span>{" "}
                    Continuously monitoring IoT systems to ensure optimal
                    performance.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      System Optimization:
                    </span>{" "}
                    Regularly updating and optimizing IoT solutions based on new
                    insights and developments.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#D800FB] to-[#751086]">
                  <img src={secureShield} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Security & Compliance</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Advanced Security Measures:
                    </span>{" "}
                    Implementing robust security measures to protect your IoT
                    systems from threats.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Regulatory Compliance :
                    </span>{" "}
                    Ensuring compliance with relevant industry standards and
                    regulations.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={Calendar} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Consultation & Needs Assessment
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Initial Consultation:
                        </span>{" "}
                        Understanding your business objectives and specific
                        needs for IoT solutions.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Customized Strategy:
                        </span>{" "}
                        Developing a tailored IoT strategy that aligns with your
                        business goals.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                      <img src={Design} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Solution Design & Development
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          IoT Architecture Design:
                        </span>{" "}
                        Designing a scalable and secure IoT architecture to meet
                        your needs.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Custom Development:
                        </span>{" "}
                        Developing customized IoT solutions that integrate
                        seamlessly with your existing systems.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                      <img src={Target} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Implementation & Integration
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Seamless Integration:
                        </span>{" "}
                        Ensuring smooth integration of IoT solutions with your
                        existing infrastructure.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Deployment Support:
                        </span>{" "}
                        Providing support during the deployment phase to ensure
                        successful implementation.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                      <img
                        src={AnalyticsPresentation}
                        alt="techaag"
                        className="w-16"
                      />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Data Management & Analytics
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Data Collection:
                        </span>{" "}
                        Implementing systems for real-time data collection and
                        exchange.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Advanced Analytics:
                        </span>{" "}
                        Utilizing advanced analytics to generate actionable
                        insights from IoT data.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="px-1 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={True} alt="techaag" className="w-12" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Continuous Monitoring & Optimization
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Performance Monitoring:
                        </span>{" "}
                        Continuously monitoring IoT systems to ensure optimal
                        performance.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          System Optimization:
                        </span>{" "}
                        Regularly updating and optimizing IoT solutions based on
                        new insights and developments.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#D800FB] to-[#751086]">
                      <img src={secureShield} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Security & Compliance</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Advanced Security Measures:
                        </span>{" "}
                        Implementing robust security measures to protect your
                        IoT systems from threats.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Regulatory Compliance :
                        </span>{" "}
                        Ensuring compliance with relevant industry standards and
                        regulations.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px]  font-light mb-5">
          Technology we use for
          <br />
          <span className="font-bold text-primary"> IoT & </span>
          <span className="font-bold text-seconday">Smart Solutions</span>
        </div>
        <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-5 justify-center hidden items-center md:grid">
            {[
              { name: "Flutter", image: Flutter },
              { name: "Python", image: Python },
              { name: "React", image: ReactJs },
              { name: "Swift", image: Swift },
              { name: "Vue", image: Vue },
              { name: "Node.js", image: NodeJS },
              { name: "Android Studio", image: AndriodStudio },
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                  <img src={item.image} alt={item.name} className="w-full" />
                </div>
                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { name: "Flutter", image: Flutter },
                { name: "Python", image: Python },
                { name: "React", image: ReactJs },
                { name: "Swift", image: Swift },
                { name: "Vue", image: Vue },
                { name: "Node.js", image: NodeJS },
                { name: "Android Studio", image: AndriodStudio },
              ].map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-auto h-32">
                    <img src={item.image} alt={item.name} className="w-full" />
                    <p className="text-center mt-2">{item.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:px-5">
            <img
              src={IOTbanner2}
              alt="techaag"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 text-[#EF7F1A] hover:text-blue-900 mt-2 md:flex-row md:items-start md:justify-start md:mt-2">
          <span className="font-semibold text-md text-center md:text-left">
            Check Iot & Smart Solution Projects
          </span>
          <img src={ArrowRight} alt="techaag" />
        </div>
      </div>
    </div>
  );
}

export default Iot;
