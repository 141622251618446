import React from "react";
import DataBanner from "../../assets/Service/DataDevops/Devopsbanner1.svg";
import Consulatation from "../../assets/Service/DataDevops/consultation.svg";
import Integration from "../../assets/Service/DataDevops/integration.svg";
import Implementation from "../../assets/Service/DataDevops/implementation.svg";
import Compliance from "../../assets/Service/DataDevops/compliance.svg";
import Design from "../../assets/Service/prototyping.svg";
import Maintenance from "../../assets/Service/maintenance.svg";
import Banner2 from "../../assets/Service/DataDevops/Devopsbanner2.svg";
import Flutter from "../../assets/Service/appdev/flutter.svg";
import ReactJs from "../../assets/Service/appdev/reactnative.svg";
import Python from "../../assets/Service/appdev/python.svg";
import Swift from "../../assets/Service/appdev/swift.svg";
import NodeJS from "../../assets/Service/appdev/nodejs.svg";
import AndriodStudio from "../../assets/Service/appdev/andriodstudio.svg";
import Vue from "../../assets/Service/appdev/vue.svg";
import ArrowRight from "../../assets/Service/arrowRight.svg";
import WhyChooseUs from "../../assets/Service/DataDevops/whyChooseUs.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function dataengineeringdevops() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col mt-20 gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Data Engineering </span>
              And
              <span className="font-bold text-seconday"> DevOps</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              Backed by{" "}
              <span className="text-primary font-bold">
                12 data engineering and DevOps integrations
              </span>{" "}
              we streamline operations and build efficient data pipelines that
              enhance your development lifecycle.
            </div>
            {/* <button className="bg-gradient-to-r from-gradient-3 to-gradient-4 w-fit text-white p-4 rounded-lg">
              Let’s Connect
            </button> */}
          </div>
          <div className="mt-24">
            <img src={DataBanner} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold"> Choose us </span>
              <span className="">
                for <br />
              </span>
              <span className="text-seconday font-bold">
                Cloud Solutions & DevOps
              </span>
            </div>
            <div>
              <img src={WhyChooseUs} alt="techaag" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold">
                {" "}
                Data Engineering &{" "}
              </span>
              <span className="text-seconday font-bold">DevOps</span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row items-center gap-5">
                <div className="p-4 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={Consulatation} alt="techaag" className="w-10" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Consultation & Assessment</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Understanding Your Needs:
                    </span>{" "}
                    We begin with a thorough consultation to understand your
                    business requirements and objectives.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Customized Strategy:
                    </span>{" "}
                    Developing a tailored cloud strategy that aligns with your
                    goals and maximizes efficiency.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-1 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                  <img src={Design} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Cloud Infrastructure Design</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Scalable Solutions:
                    </span>{" "}
                    Designing a scalable cloud infrastructure that grows with
                    your business.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Security Integration:
                    </span>{" "}
                    Incorporating advanced security measures to protect your
                    data.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                  <img src={Integration} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">DevOps Integration</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Automated Workflows:
                    </span>{" "}
                    Implementing DevOps practices for continuous integration and
                    delivery, ensuring faster.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Collaboration Tools:
                    </span>{" "}
                    Enhancing team collaboration with effective tools and
                    practices.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                  <img src={Implementation} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Implementation & Migration</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Seamless Transition:
                    </span>{" "}
                    Ensuring a smooth migration to the cloud with minimal
                    disruption to your operations.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Expert Support:
                    </span>{" "}
                    Providing ongoing support to ensure your cloud
                    infrastructure runs smoothly.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                  <img src={Maintenance} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Continuous Monitoring & Optimization
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Proactive Monitoring:
                    </span>{" "}
                    Continuously monitoring your cloud environment to identify
                    and resolve issues promptly.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Performance Optimization:
                    </span>{" "}
                    Regularly optimizing performance to ensure your cloud
                    infrastructure is running at its best.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                  <img src={Compliance} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Compliance & Security</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Regulatory Adherence:
                    </span>{" "}
                    Ensuring compliance with industry standards such as GDPR,
                    HIPAA, and ISO.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Advanced Security Measures:
                    </span>{" "}
                    Implementing real-time threat detection and proactive
                    response strategies.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={Consulatation} alt="techaag" className="w-10" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Consultation & Assessment
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Understanding Your Needs:
                        </span>{" "}
                        We begin with a thorough consultation to understand your
                        business requirements and objectives.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Customized Strategy:
                        </span>{" "}
                        Developing a tailored cloud strategy that aligns with
                        your goals and maximizes efficiency.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                      <img src={Design} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Cloud Infrastructure Design
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Scalable Solutions:
                        </span>{" "}
                        Designing a scalable cloud infrastructure that grows
                        with your business.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Security Integration:
                        </span>{" "}
                        Incorporating advanced security measures to protect your
                        data.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                      <img src={Integration} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">DevOps Integration</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Automated Workflows:
                        </span>{" "}
                        Implementing DevOps practices for continuous integration
                        and delivery, ensuring faster.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Collaboration Tools:
                        </span>{" "}
                        Enhancing team collaboration with effective tools and
                        practices.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                      <img
                        src={Implementation}
                        alt="techaag"
                        className="w-16"
                      />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Implementation & Migration
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Seamless Transition:
                        </span>{" "}
                        Ensuring a smooth migration to the cloud with minimal
                        disruption to your operations.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Expert Support:
                        </span>{" "}
                        Providing ongoing support to ensure your cloud
                        infrastructure runs smoothly.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                      <img src={Maintenance} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Continuous Monitoring & Optimization
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Proactive Monitoring:
                        </span>{" "}
                        Continuously monitoring your cloud environment to
                        identify and resolve issues promptly.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Performance Optimization:
                        </span>{" "}
                        Regularly optimizing performance to ensure your cloud
                        infrastructure is running at its best.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                      <img src={Compliance} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Compliance & Security</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Regulatory Adherence:
                        </span>{" "}
                        Ensuring compliance with industry standards such as
                        GDPR, HIPAA, and ISO.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Advanced Security Measures:
                        </span>{" "}
                        Implementing real-time threat detection and proactive
                        response strategies.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px]  font-light mb-5">
          Technology we use for
          <br />
          <span className="font-bold text-primary"> Data Engineering & </span>
          <span className="font-bold text-seconday"> DevOps</span>
        </div>
        <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-5 justify-center items-center hidden md:grid">
            {[
              { name: "Flutter", image: Flutter },
              { name: "Python", image: Python },
              { name: "React", image: ReactJs },
              { name: "Swift", image: Swift },
              { name: "Vue", image: Vue },
              { name: "Node.js", image: NodeJS },
              { name: "Android Studio", image: AndriodStudio },
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                  <img src={item.image} alt={item.name} className="w-full" />
                </div>
                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { name: "Flutter", image: Flutter },
                { name: "Python", image: Python },
                { name: "React", image: ReactJs },
                { name: "Swift", image: Swift },
                { name: "Vue", image: Vue },
                { name: "Node.js", image: NodeJS },
                { name: "Android Studio", image: AndriodStudio },
              ].map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-auto h-32">
                    <img src={item.image} alt={item.name} className="w-full" />
                    <p className="text-center mt-2">{item.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:px-5">
            <img
              src={Banner2}
              alt="techaag"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 text-[#EF7F1A] hover:text-blue-900 mt-2 md:flex-row md:items-start md:justify-start md:mt-2">
          <span className="font-semibold text-md text-center md:text-left">
            Check Data Engineering & DevOps Projects
          </span>
          <img src={ArrowRight} alt="techaag" />
        </div>
      </div>

      <div className="container mx-auto gap-5 relative p-5 md: mb-10 py-20 lg:px-15">
        <div className="grid grid-cols-1 gap-5 justify-between items-center md:grid-cols-2">
          <div className="font-bold text-lg text-center w-full md:text-left md:w-3/4 md:text-2xl">
            Choose us for
            <br />
            Data Engineering & DevOps
          </div>
          <div className="flex justify-center md:justify-end"></div>
        </div>
      </div>
    </div>
  );
}

export default dataengineeringdevops;
