import React from "react";
import Banner from "../../assets/Service/Cybersecurity/cybersecuritybanner1.svg";
import Calendar from "../../assets/Service/Cybersecurity/calendar.png";
import Banner2 from "../../assets/Service/Cybersecurity/cybersecuritybanner2.svg";
import Implementation from "../../assets/Service/Cybersecurity/implementation.png";
import Compliance from "../../assets/Service/Cybersecurity/compliance.png";
import Response from "../../assets/Service/Cybersecurity/response.png";
import Continuous from "../../assets/Service/Cybersecurity/continuous.png";
import Audit from "../../assets/Service/Cybersecurity/audit.png";
import Flutter from "../../assets/Service/appdev/flutter.svg";
import ReactJs from "../../assets/Service/appdev/reactnative.svg";
import Python from "../../assets/Service/appdev/python.svg";
import Swift from "../../assets/Service/appdev/swift.svg";
import NodeJS from "../../assets/Service/appdev/nodejs.svg";
import AndriodStudio from "../../assets/Service/appdev/andriodstudio.svg";
import Vue from "../../assets/Service/appdev/vue.svg";
import WhyChooseUs from "../../assets/Service/Cybersecurity/whyChooseUs.svg";
import ArrowRight from "../../assets/Service/arrowRight.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function cyberSecurity() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col mt-20 gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Cybersecurity &</span>
              <span className="font-bold text-seconday"> Compliance</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              With expertise in securing over{" "}
              <span className="text-primary font-bold">
                25 enterprise systems
              </span>{" "}
              our cybersecurity solutions guarantee robust protection against
              evolving threats, keeping your business compliant and secure
            </div>
            {/* <button className="bg-gradient-to-r from-gradient-3 to-gradient-4 w-fit text-white p-4 rounded-lg">
              Let’s Connect
            </button> */}
          </div>
          <div className="mt-24">
            <img src={Banner} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold"> Choose us </span>
              <span className="">
                for <br />
              </span>
              <span className="text-seconday font-bold">
                Cybersecurity & Compliance
              </span>
            </div>
            <div>
              <img src={WhyChooseUs} alt="techaag" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold"> Cybersecurity & </span>
              <span className="text-seconday font-bold">
                Compliance Process
              </span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={Calendar} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Risk Assessment & Planning</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Initial Consultation:
                    </span>{" "}
                    Understanding your specific security needs and regulatory
                    requirements.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Customized Security Plan:
                    </span>{" "}
                    Developing a tailored security strategy that addresses
                    potential vulnerabilities and compliance needs.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-1 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                  <img
                    src={Implementation}
                    alt="techaag"
                    className="w-20 lg:w-16"
                  />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Implementation of Security Measures
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Advanced Security Solutions:
                    </span>{" "}
                    Deploying state-of-the-art security technologies to protect
                    your digital assets.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Real-time Monitoring:
                    </span>{" "}
                    Continuous monitoring of your systems to detect and respond
                    to threats in real-time.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                  <img src={Compliance} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Compliance Management</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Regulatory Adherence:
                    </span>{" "}
                    Ensuring compliance with industry standards such as GDPR,
                    HIPAA, and ISO.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Policy Development:
                    </span>{" "}
                    Assisting in the creation and implementation of security
                    policies that meet regulatory requirements.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                  <img src={Response} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">
                    Incident Response & Recovery
                  </span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Proactive Response:
                    </span>{" "}
                    Implementing proactive response strategies to mitigate the
                    impact of security incidents.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Recovery Planning:
                    </span>{" "}
                    Developing and executing recovery plans to restore normal
                    operations quickly and efficiently.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                  <img src={Continuous} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Continuous Improvement</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Ongoing Assessment:
                    </span>{" "}
                    Regularly assessing your security posture to identify and
                    address new vulnerabilities.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Training & Awareness:
                    </span>{" "}
                    Providing training and awareness programs to ensure your
                    staff is equipped to handle security challenges.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-2 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                  <img src={Audit} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Audit & Reporting</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Comprehensive Audits:
                    </span>{" "}
                    Conducting thorough security audits to ensure compliance and
                    identify areas for improvement.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Detailed Reporting:
                    </span>{" "}
                    Providing detailed reports on security status, incidents,
                    and compliance adherence.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-4 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={Calendar} alt="techaag" className="w-10" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Risk Assessment & Planning
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Initial Consultation:
                        </span>{" "}
                        Understanding your specific security needs and
                        regulatory requirements.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Customized Security Plan:
                        </span>{" "}
                        Developing a tailored security strategy that addresses
                        potential vulnerabilities and compliance needs.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-1 rounded-md bg-gradient-to-r from-[#EF7F1A] to-[#814209]">
                      <img
                        src={Implementation}
                        alt="techaag"
                        className="w-16"
                      />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Implementation of Security Measures
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Advanced Security Solutions:
                        </span>{" "}
                        Deploying state-of-the-art security technologies to
                        protect your digital assets.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Real-time Monitoring:
                        </span>{" "}
                        Continuous monitoring of your systems to detect and
                        respond to threats in real-time.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                      <img src={Compliance} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Compliance Management</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Regulatory Adherence:
                        </span>{" "}
                        Ensuring compliance with industry standards such as
                        GDPR, HIPAA, and ISO.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Policy Development:
                        </span>{" "}
                        Assisting in the creation and implementation of security
                        policies that meet regulatory requirements.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                      <img src={Response} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Incident Response & Recovery
                      </span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Proactive Response:
                        </span>{" "}
                        Implementing proactive response strategies to mitigate
                        the impact of security incidents.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Recovery Planning:
                        </span>{" "}
                        Developing and executing recovery plans to restore
                        normal operations quickly and efficiently.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#F402A2] to-[#740950]">
                      <img src={Continuous} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Continuous Improvement</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Ongoing Assessment:
                        </span>{" "}
                        Regularly assessing your security posture to identify
                        and address new vulnerabilities.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Training & Awareness:
                        </span>{" "}
                        Providing training and awareness programs to ensure your
                        staff is equipped to handle security challenges.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#000000] to-[#434F51]">
                      <img src={Audit} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Audit & Reporting</span>
                      <div className="text-paragraph text-sm">
                        <span className="text-primary font-bold">
                          {" "}
                          Comprehensive Audits:
                        </span>{" "}
                        Conducting thorough security audits to ensure compliance
                        and identify areas for improvement.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Detailed Reporting:
                        </span>{" "}
                        Providing detailed reports on security status,
                        incidents, and compliance adherence.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px]  font-light mb-5">
          Technology we use for
          <br />
          <span className="font-bold text-primary"> Cybersecurity & </span>
          <span className="font-bold text-seconday"> Compliance &</span>
        </div>
        <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-5 justify-center items-center hidden md:grid">
            {[
              { name: "Flutter", image: Flutter },
              { name: "Python", image: Python },
              { name: "React", image: ReactJs },
              { name: "Swift", image: Swift },
              { name: "Vue", image: Vue },
              { name: "Node.js", image: NodeJS },
              { name: "Android Studio", image: AndriodStudio },
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                  <img src={item.image} alt={item.name} className="w-full" />
                </div>
                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { name: "Flutter", image: Flutter },
                { name: "Python", image: Python },
                { name: "React", image: ReactJs },
                { name: "Swift", image: Swift },
                { name: "Vue", image: Vue },
                { name: "Node.js", image: NodeJS },
                { name: "Android Studio", image: AndriodStudio },
              ].map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-auto h-32">
                    <img src={item.image} alt={item.name} className="w-full" />
                    <p className="text-center mt-2">{item.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:px-5">
            <img
              src={Banner2}
              alt="techaag"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 text-[#EF7F1A] hover:text-blue-900 mt-2 md:flex-row md:items-start md:justify-start md:mt-2">
          <span className="font-semibold text-md text-center md:text-left">
            Check Cybersecurity & Compliance Projects
          </span>
          <img src={ArrowRight} alt="techaag" />
        </div>
      </div>

      <div className="container mx-auto gap-5 relative p-5 md: mb-10 py-20 lg:px-15">
        <div className="grid grid-cols-1 gap-5 justify-between items-center md:grid-cols-2">
          <div className="font-bold text-lg text-center w-full md:text-left md:w-3/4 md:text-2xl">
            Choose us for
            <br />
            Cybersecurity & Compliance
          </div>
          <div className="flex justify-center md:justify-end"></div>
        </div>
      </div>
    </div>
  );
}

export default cyberSecurity;
