import React from "react";
import WhiteLabelsvg from "../../assets/product/whiteLabel.svg";

function WhiteLabel() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 md:grid-cols-2 justify-between items-center gap-5 mt-10">
          <div className="flex flex-col gap-5 mt-10 md:mt-20">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">White </span>
              <span className="font-bold text-seconday">Label Solution</span>
            </div>
            <div className="text-paragraph font-normal text-sm text-justify md:mb-10">
              White Label Travel Portal Solution eases the work of the travel agents and makes the services comfortable for the travelers. In most of the cases, secured servers are used to host white labels and connect it to the travel reservation system. White Label Solutions An improved visibility plays a significant role in promoting the business infrastructure of a travel company. Travel Portal Solution has come up with a host of effective tools for the travel companies that can enhance their visibility to a maximum number of customers. If you want to get long-term website traffic without any hassle, then you should try White Label Solution. A strong bond between the travel company and their clients is always good for promoting travel business. Integrated architecture and user-friendly operations provided by Travel Portal Solution attract new customers and strengthen the bond between existing customers and the travel company.
            </div>
          </div>
          <div className="flex justify-center mt-10 md:mt-20">
            <img src={WhiteLabelsvg} alt="techaag" className="w-full h-auto md:w-3/4 lg:w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhiteLabel;
