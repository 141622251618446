import React, { useState } from "react";
import Banner1 from "../../assets/product/travelPortalDevelopment/banner1.svg";
import Banner2 from "../../assets/product/travelPortalDevelopment/banner2.svg";
import Banner3 from "../../assets/product/travelPortalDevelopment/banner3.svg";
import Banner6 from "../../assets/product/travelPortalDevelopment/banner6.svg";
import Banner7 from "../../assets/product/travelPortalDevelopment/banner7.svg";
import Banner8 from "../../assets/product/travelPortalDevelopment/banner8.svg";
import Banner9 from "../../assets/product/travelPortalDevelopment/banner9.svg";
import Banner10 from "../../assets/product/travelPortalDevelopment/banner10.svg";
import Banner11 from "../../assets/product/travelPortalDevelopment/banner11.svg";

import flightIcon from "../../assets/product/flightIcon.svg";
import hotelIcon from "../../assets/product/hotelIcon.svg";
import carIcon from "../../assets/product/carIcon.svg";

//Flight
import AirAsia from "../../assets/collaborators/Flight/Air asia.svg"
import AzulBrazilian from "../../assets/collaborators/Flight/Azul_Brazilian_Airlines-Logo.wine.svg"
import CebuPacific from "../../assets/collaborators/Flight/Cebu_Pacific-Logo.wine.svg";
import Amadeus from "../../assets/collaborators/Flight/F-Amadeus.svg";
// import Babylon from "../../assets/collaborators/Flight/F-Babylon.svg";
import Sabre from "../../assets/collaborators/Flight/F-sabre.svg";
import SingaporeAirlines from "../../assets/collaborators/Flight/F-singapore-airlines.svg";
import Flynas from "../../assets/collaborators/Flight/Flynas-Logo.wine.svg";
import GarudaIndonesia from "../../assets/collaborators/Flight/Garuda_Indonesia-Logo.wine.svg";
import GolTransportesAereos from "../../assets/collaborators/Flight/Gol_Transportes_Aéreos-Logo.wine.svg";
import Interjet from "../../assets/collaborators/Flight/Interjet-Logo.wine.svg";
import JetstarAirways from "../../assets/collaborators/Flight/Jetstar_Airways-Logo.wine.svg";
import LatamAirlines from "../../assets/collaborators/Flight/Latam airlines.svg";
import LionAir from "../../assets/collaborators/Flight/Lion_Air-Logo.wine.svg";
import OlympicAir from "../../assets/collaborators/Flight/Olympic_Air-Logo.wine.svg";
// import PKFARE from "../../assets/collaborators/Flight/PKFARE.png.svg";
import Qantas from "../../assets/collaborators/Flight/Qantas-Logo.wine.svg";
import Scoot from "../../assets/collaborators/Flight/Scoot-Logo.wine.svg";
import SwissInternationalAirLines from "../../assets/collaborators/Flight/Swiss_International_Air_Lines-Logo.wine.svg";
import VivaColombia from "../../assets/collaborators/Flight/Viva Colombia.svg";
import AustrianAirlines from "../../assets/collaborators/Flight/austrian-airlines-4.svg";
import BrusselsAirlines from "../../assets/collaborators/Flight/brussels-airlines-seeklogo.svg";
import CopaAirlines from "../../assets/collaborators/Flight/copa-airlines.svg";
import EmiratesAirlines from "../../assets/collaborators/Flight/emirates-airlines.svg";
import Indigo from "../../assets/collaborators/Flight/indigo-vector-logo-2022.svg";
import Lufthansa from "../../assets/collaborators/Flight/lufthansa-4.svg";
// import VivaAerobus from "../../assets/collaborators/Flight/viva aerobus.svg";
import WizzAir from "../../assets/collaborators/Flight/wizz-air.svg";







//Hotel
import GrandHyatt from "../../assets/collaborators/Hotels svg file/Grand Hyatt.svg"
import HiltonHotel from "../../assets/collaborators/Hotels svg file/HiltonHotelsLogo.svg"
import IHGHotels from "../../assets/collaborators/Hotels svg file/IHG_Hotels_&_Resorts_logo.svg"
import OberoiHotel from "../../assets/collaborators/Hotels svg file/Oberoi Hotel & Resorts Logo Vector.svg"
import Radisson from "../../assets/collaborators/Hotels svg file/Radisson_Blu_logo.svg"
import Atlantis from "../../assets/collaborators/Hotels svg file/atlantis-the-palm-logo-vector.svg"
import CarRenting from "../../assets/collaborators/Hotels svg file/car-renting6899.jpg"
import Kaaba from "../../assets/collaborators/Hotels svg file/depositphotos_264546918-stock-illustration-kaaba-vector-illustration-icon.jpg"
import Fairmont from "../../assets/collaborators/Hotels svg file/fairmont.svg"
import JumeirahBeachHotel from "../../assets/collaborators/Hotels svg file/jumeirah-beach-hotel-logo-vector.svg"
import RoyalMeridien from "../../assets/collaborators/Hotels svg file/le-royal-meridien.svg"
import Moevenpick from "../../assets/collaborators/Hotels svg file/moevenpick.svg"
import OberoiHotels from "../../assets/collaborators/Hotels svg file/oberoi hotel logo.svg"
import Oberoi from "../../assets/collaborators/Hotels svg file/oberoi symbol.png"
import Shangri from "../../assets/collaborators/Hotels svg file/shangri-la-2.svg"
import Sheraton from "../../assets/collaborators/Hotels svg file/sheraton-hotels-resorts-2.svg"
import Suha from "../../assets/collaborators/Hotels svg file/suha hospitality.svg"
import Swissotel from "../../assets/collaborators/Hotels svg file/swissotel.svg"
import TajHotel from "../../assets/collaborators/Hotels svg file/taj hotel.svg"
import RitzCarlton from "../../assets/collaborators/Hotels svg file/the-ritz-carlton.svg"


//Activies
import Headout from "../../assets/collaborators/activities/A-Headout.png"
import Viator from "../../assets/collaborators/activities/A-Viator.svg"
import hotelbeds from "../../assets/collaborators/activities/A-hotelbeds.svg"
import klook from "../../assets/collaborators/activities/A-klook.svg"

//Car Rental

import Carnect from "../../assets/collaborators/carRent/Car Rental-Carnect.png"

//transfers
import Limos4 from "../../assets/collaborators/transfers/T-Limos4.png"
import Tboholidays from "../../assets/collaborators/transfers/T-Tboholidays.com.png"
import Transferz from "../../assets/collaborators/transfers/T-Transferz.com.png"
import hotelBeds from "../../assets/collaborators/transfers/T-hotelbeds.svg"

//Insurence
import Generali from "../../assets/collaborators/insurence/generali.svg"

//Payment
import Mastercard from "../../assets/collaborators/payment/mastercard-svgrepo-com.svg"
import Payu from "../../assets/collaborators/payment/PayU.svg"
import Stripe from "../../assets/collaborators/payment/stripe.svg"
import KN from "../../assets/collaborators/payment/KN Pay.svg"
import Piraeus from "../../assets/collaborators/payment/Piraeus Bank.svg"
import Vindi from "../../assets/collaborators/payment/Vindi.svg"
import AlgeriePoste from "../../assets/collaborators/payment/algerie-poste.svg"
import BankOfBeruit from "../../assets/collaborators/payment/bank of beruit.svg"
import QnbFinansbank from "../../assets/collaborators/payment/qnb-finansbank-logo.svg"






function TravelPortalDevelopment() {
    const [activeSection, setActiveSection] = useState("flight-booking-engine");
    const [activeBox, setActiveBox] = useState(1);

    const headings = [
        { id: "flight-booking-engine", title: "Flight Booking Engine" },
        { id: "hotel-booking-engine", title: "Hotel Booking Engine" },
        { id: "bus-booking-engine", title: "Bus Booking Engine" },
        { id: "car-bookin-engine", title: "Car Booking Engine" },
        { id: "central-reservation-system", title: "Central Reservation System" },
        { id: "tour-management-system", title: "Tour Management System" },
    ];

    const boxes = [
        {
            id: 1, title: "Flights", svgs: [
                AirAsia,
                AzulBrazilian,
                CebuPacific,
                Amadeus,
                // Babylon, 
                Sabre,
                SingaporeAirlines,
                Flynas,
                GarudaIndonesia,
                GolTransportesAereos,
                Interjet,
                JetstarAirways,
                LatamAirlines,
                LionAir,
                OlympicAir,
                // PKFARE, 
                Qantas,
                Scoot,
                SwissInternationalAirLines,
                VivaColombia,
                AustrianAirlines,
                BrusselsAirlines,
                CopaAirlines,
                EmiratesAirlines,
                Indigo,
                Lufthansa,
                // VivaAerobus, 
                WizzAir
            ]
        },
        { id: 2, title: "Hotels", svgs: [GrandHyatt, HiltonHotel, IHGHotels, OberoiHotel, Radisson, Atlantis, CarRenting, Kaaba, Fairmont, RitzCarlton, TajHotel, Swissotel, Suha, Sheraton, Shangri, Oberoi, OberoiHotels, Moevenpick, RoyalMeridien, JumeirahBeachHotel] },
        { id: 3, title: "Activities", svgs: [klook, hotelbeds, Viator, Headout] },
        { id: 4, title: "Car Rental", svgs: [Carnect] },
        { id: 5, title: "Transfers", svgs: [Limos4, Tboholidays, Transferz, hotelBeds] },
        { id: 6, title: "Insurance", svgs: [Generali] },
        { id: 7, title: "Payment Gateway", svgs: [Mastercard, Payu, Stripe, KN,Piraeus,Vindi,AlgeriePoste,BankOfBeruit,QnbFinansbank] },
    ];

    return (
        <div>
            <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                <div className="grid grid-cols-1 justify-between items-center gap-10 md:grid-cols-2">
                    <div className="flex flex-col mr-5 gap-5 mt-20">
                        <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
                            <span className="font-bold text-primary">Travel  </span>
                            <span className="font-bold text-seconday">Portal Development </span>
                        </div>
                        {/* Table of Contents */}
                        <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                            <div className="font-bold text-xl md:text-2xl">
                                Table of Content
                            </div>
                            <div className="container grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-7 p-5 mb-10 mt-4">
                                {headings.map((heading) => (
                                    <div
                                        key={heading.id}
                                        className={`p-2 text-sm font-semibold border border-gray-400 rounded-lg cursor-pointer text-center ${activeSection === heading.id ? "bg-primary text-white" : "bg-background"
                                            }`}
                                        onClick={() => setActiveSection(heading.id)}
                                    >
                                        {heading.title}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>

                    <div className="flex items-center justify-center w-full h-[70%] mt-20">
                        <img src={Banner1} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>
            </div>



            {/* Sections */}
            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                {activeSection === "flight-booking-engine" && (
                    <div>
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="flex flex-row">
                                    <div className="py-3 px-3 mb-10 font-bold w-fit">
                                        Flight Booking Engine
                                    </div>
                                    <div>
                                        <img src={flightIcon} alt={`flight`} className=" " />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            A travel portal is incomplete until it has a flight booking engine. Flight Booking Engine is a platform where a facility of online flight ticket bookings is provided to the tourists who process bookings through internet. Travel agent/ customers/ corporate booking can be made on one platform. <br />
                                            FBT Technology is one of the top leading travel technologies company based in delhi, India, Specialist in developing Flight Booking/Reservation System, Flight Booking Engine, We develop portal as per customers’ requirements for Travel Companies, Travel Agency and Agents has constantly been one of the biggest business happening towards the flight ticket booking system in the travel market.We help our customers to access the Global Distribution System, XML and API Providers, To find the best airline fare, low cost air carriers, and domestic and international air carriers for economical air transit.\
                                        </ol>
                                    </div>
                                </div>
                                <div className="py-3 px-3 text-xl mt-10 mb-10 font-bold w-fit">
                                    BENEFITS OF AIRLINE RESERVATION SYSTEM:
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Flight Tickets Booking Softwares designed and developed under the norms of professionalism. <br />
                                            •Flight booking enginefor website provides fast and error free online reservation facility to the users. <br />
                                            •There is a service of reservation cancellation. <br />
                                            •Notification through email regarding ticket bookings, cancellation, flight delayed etc. <br />
                                            •Appealing banners are available for the purpose of advertisement. <br />
                                            •Comparison option to the users so that they can compare fares by different airlines. <br />
                                            •API integratedbooking solution. <br />
                                            •Trustworthy and reliable. <br />
                                            •Online payment facilityis available for the booking makers.

                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center w-full md:w-[30%] mt-20 mb-10 md:mt-0 order-1 md:order-2 justify-center">
                                <img
                                    src={Banner2}
                                    alt="techaag"
                                    className="w-full h-auto sm:w-3/4 md:w-full lg:w-3/4 xl:w-full max-w-xs md:max-w-none"
                                />
                            </div>


                        </div>

                        <div className="flex flex-col-reverse md:flex-row mt-10">
                            <div className="flex flex-col gap-2 items-start">
                                <div className="py-3 px-3 text-xl font-bold w-fit">
                                    KEY GDS, XML, API SUPPLIERS:
                                </div>

                                <div className="flex flex-row ">
                                    <div className="px-5">
                                        <div className="py-3 font-bold mt-4 w-fit">
                                            GDS SUPPLIER
                                        </div>
                                        <ol className=" text-paragraph text-sm">
                                            •Sabre <br />
                                            •Galileo / TravelPort <br />
                                            •Amadeus <br />
                                            •Apolo GDS <br />
                                            •Abacus <br />
                                            •Worldspan
                                        </ol>
                                    </div>

                                    <div className="px-5">
                                        <div className="py-3 font-bold mt-4 w-fit">
                                            XML SUPPLIER
                                        </div>

                                        <ol className=" text-paragraph text-sm">
                                            •Yatra  <br />
                                            •Arzoo <br />
                                            •Travel Boutique Online <br />
                                            •Riya Travels <br />
                                            •Akbar Travels <br />
                                            •Expedia <br />
                                            •Travelsky

                                        </ol>
                                    </div>

                                    <div className="px-5">
                                        <div className="py-3 font-bold mt-4 w-fit">
                                            Third Party Flight APIs
                                        </div>
                                        <ol className=" text-paragraph text-sm">
                                            •Arzoo <br />
                                            •Cleartrip <br />
                                            •Makemytrip <br />
                                            •Travel Boutique Online <br />
                                            •Riya Travels <br />
                                            •Mystifly (My farebox) <br />
                                            •Multicom
                                        </ol>
                                    </div>

                                </div>
                            </div>
                            <div className="flex items-center w-full md:w-[40%] mt-6 mb-6 md:mt-0 order-1 md:order-2 justify-center">
                                <img
                                    src={Banner3}
                                    alt="techaag"
                                    className="w-[60%] h-auto max-h-[60%] md:w-3/4 lg:w-[90%] xl:w-3/4"
                                />
                            </div>
                        </div>
                    </div>
                )}


                {activeSection === "hotel-booking-engine" && (
                    <div>
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="flex flex-row">
                                    <div className="py-3 px-3 mb-10 font-bold w-fit">
                                        Hotel Booking Engine
                                    </div>
                                    <div>
                                        <img src={hotelIcon} alt={`hotel`} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            Hotel Booking Engine is a feature packed tool that allows you to take direct bookings through your website anytime.We develop Hotel Booking Portal and integrate multiple hotel suppliers. We integrate the below listed Suppliers XML and many more through the hotel reservation software. To access wide array of inventory online with Hotel Booking Engines that are integrated with most advanced XML integration programs like
                                        </ol>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    RoomsXML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •RoomsXML allows you to connect over 80,000 properties across the globe and growing.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    DOTW XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •integration of a travel portal with Destinations Of The World (DOTW) allows you to access travel portals of around 151 countries.
                                        </ol>
                                    </div>
                                </div>


                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    GTA XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Integration GTA enables travel associates.
                                        </ol>
                                    </div>
                                </div>


                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    Hotelbeds XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •This is the most vital B2B partner that can be integrated to access information about 45000 hotels operating world over
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    HotelsPro XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •This integration makes your hotel booking engine 100% interactive and a faster online reservation system.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    JacTravel XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •This integration allows cloud based comprehensive hotel booking search and reservation solutions on both B2B/B2C platforms.
                                        </ol>
                                    </div>
                                </div>


                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    Kuoni XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •You can provide tailored logistic and travel services with Kuoni, a Swiss based travel operator.
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                                <img src={Banner6} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row mt-10">
                            <div className="flex flex-col gap-2 items-start">
                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    MIKI Travel XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •This can be a trusted integration for faster transactions, cost-free packages location, and other operational benefits.
                                        </ol>
                                    </div>
                                </div>


                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    Tourico XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Connect to 150 international hotel booking websites, get countless merchant hotel agreements with this integration.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    Travco XML Integration
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            One among the fastest growing travel and tourism business in the world that can connect you to hotels in more than 70 countries.
                                            <br />•OYO ROOMS XML/ API <br />
                                            •FAB HOTELS XML/API <br />
                                            •ARZOO API <br />
                                            •LOW COST BED XML/API
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    HIGHER CONVERSION RATIO
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Customer is always on hotel website unlike other hotel booking software in the market and this results in converting visitors into brokers.<br />
                                            •Our Payment Gateway for hotels provides retry option for failed transactions which results in higher completed transactions.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    SIMPLE DASHBOARD
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •FBT Technology hotel booking software allows you to have complete control over your rates and inventory from a single window.<br />
                                            •Define different room types and choose to sell your rooms using one of the pricing methods available (price per room, price per person, or detailed pricing methods) on the FBT Technology hotel reservation system.<br />
                                            •Define different deposit, cancellation and no-show policies for each rate type.<br />
                                            •Define extra services or packages (Airport transfer, City tours, Diving courses, etc,.).
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    DIRECT AGENT LOGIN
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Hotel Agent can directly login into your website through online hotel booking system and can see their contracted rates and can make a quick booking, reducing the phone calls and emails for the management and the confirmation of their reservations will allow you to save time.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    INSTANT CONFIRMATION
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Messages will be sent automatically to customers and to the hotel when a reservation is made, cancelled or changed through FBT Technology online hotel reservation software.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    FULLY CUSTOMIZATION
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •It is a completely customizable and flexible online hotel booking system that is suitable for all types of accommodations including 5-star hotels, boutique hotels, Homestays, B&B’s, Serviced apartments.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    DEALS & PROMOTIONS
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Very easy to add/remove deals from hotel website.<br />
                                            •Create promotional codes and communicate these using emails, Facebook, Twitter etc.<br />
                                            •Define promotions to encourage early bookings or long stays.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    MULTIPLE PAYMENT OPTIONS
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •Receive online payments by credit Card, Debit Card or Net banking through the easy to use payment gateway for hotels.<br />
                                            •Or Recieve payments from customer on Arrival.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    DETAILED REPORTS
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            •See a Calendar of your customer check-ins.<br />
                                            •See all your bookings at a single page.
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center w-full mt-20 md:mt-0 order-1 md:order-2 justify-center">
                                <img src={Banner7} alt="techaag" className="w-full  mx-auto md:w-3/4 lg:w-full" />
                            </div>
                        </div>
                    </div>
                )}


                {activeSection === "bus-booking-engine" && (
                    <div>
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="flex flex-row">
                                    <div className="py-3 px-3 text-xl mb-10 font-bold w-fit">
                                        Bus Booking Engine
                                    </div>
                                    <div>
                                        <img src={carIcon} alt={`car`} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            FBT Technology provides bus booking engine development solutions to Booking Portal Owners or the companies who wants to develop Travel Portals, exclusively with the concept of Bus Booking business.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 mb-10 font-bold w-fit">
                                    CMS MODULE
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            FBT Technology provide complete Bus Booking Engine Development, which will have different options like route creation, routes allocation to different vendors and different buses, seat selection and, modify search, online booking etc. One of the largest modules here will be Vendor Management System (VMS), using which the portal administrator can create several vendors who can create, add, update, delete their Buses on different sildenafil citrate. The routes can be prepared both by the Portal admin as well as vendor, depending upon the interest of the portal/website owner.
                                        </ol>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    API INTEGRATION MODULE
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            • Customizable design and layout <br />• Completely integrated into your website <br />
                                            • Dynamic packaging and shopping cart capabilities <br />
                                            • Notification through email regarding ticket bookings, cancellation, flight delayed etc. <br />
                                            • Room upgrades capabilities <br />
                                            • Advanced and flexible yield capabilities <br />
                                            • Detailed conversion analysis <br />
                                            • Detailed reporting and revenue tracking <br />
                                            • Quick loading custom light weight website <br />
                                            • Business to consumer (Guest and User Modules) <br />
                                            • Login with social networking site like Face book to capture user information. <br />
                                            • Login with social networking site like Face book to capture user information. <br />
                                            • Admin can up loadable banner images <br />
                                            • Registered SMS on Company name Ex. LM – XYZ <br />
                                            • Predefined Email Notifications at registrations, password intimation, booking, cancel, <br />
                                            • Online Page Search Engine Optimization required for the Internet Promotion <br />
                                            • Every feature is predefined and design or style sheet not editable from customer end. <br />
                                            • Guest Login/Register• Agent Login/Register <br />
                                            • Manage your bookings online <br />
                                            • View / Print your E-Ticket: Now print your air E-ticket instantly! <br />
                                            • Cancel your booking online. <br />
                                            • Check your ticket refund status. <br />
                                            • Make a Payment: Pay your booking amount here. <br />
                                            • Deposit Summary Report <br />
                                            • Consolidated Report <br />
                                            • Booking Sales and Commission Report <br />
                                            • Visa | MasterCard | Maestro | AMEX | JCB | Diners – Credit Card Option• Commission Generated <br />
                                            • Option to show and hide commission and markup <br />
                                            • Can send the deposit detail and upload transaction receipt for the admin.


                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    Suppliers
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            • RED BUS <br />
                                            • ABHI BUS <br />
                                            • TBO <br />
                                            • HERMES
                                        </ol>
                                    </div>
                                </div>


                            </div>

                            <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                                <img src={Banner8} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                            </div>
                        </div>

                    </div>
                )}


                {activeSection === "car-bookin-engine" && (
                    <div>
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="flex flex-row">
                                    <div className="py-3 px-3 text-xl mb-10 font-bold w-fit">
                                        Car Booking Engine
                                    </div>
                                    <div>
                                        <img src={carIcon} alt={`car`} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            FBT Technology is a service provider for developing best Car Booking Engine to travel portals across the planet. Our Car Rental Booking Engines helps in developing the sales of travel agencies rendering car rental and booking services. Our API integrated Car Booking Engine enables the users to access car data of car rental agencies across the planet.Car Rental Integration software with generic tramadol not just make more perfectibility to the Car Booking travel portal but it also helps your customers to have inventories of multiple car rental booking companies and XML/API suppliers.<br />FBT Technology also help you to integrate third party XML/API of Car Rental Booking system on your portal.
                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 mb-10 font-bold w-fit">
                                    ONLINE CAR BOOKING SERVICES:
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            • Rental Vehicle Booking Engine • Rental Vehicle Reservation System • Supplier Connectivity • Channel Management
                                        </ol>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    SPECIAL FEATURES OF CAR BOOKING PORTAL
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            • Secure Control Panel user and role permissions. <br />
                                            • Customizable design and layout <br />
                                            • Completely integrated into your website <br />
                                            • Quick and easy quote. <br />
                                            • Real time availability. <br />
                                            • Payment Integration. <br />
                                            • Multi-language support. <br />
                                            • Customizable auto responders in different languages. <br />
                                            • Unlimited pick-up and drop-off locations. <br />
                                            • Customize all fees for different locations. <br />
                                            • Instant reservation confirmation number. <br />
                                            • Travel Agent interface. <br />
                                            • Promotions discount rates. <br />
                                            • Promotional coupon codes. <br />
                                            • Booking Management <br />
                                            • Markup Management <br />
                                            • Built on Microsoft .NET & AJAX technologies. <br />
                                            • Guest Login/Register <br />
                                            • Agent Login/Register <br />
                                            • Manage your bookings online <br />
                                            • View / Print your E-Ticket: Now print your air E-ticket instantly! <br />
                                            • Cancel your booking online. <br />
                                            • Check your ticket refund status. <br />
                                            • Make a Payment: Pay your booking amount here. <br />
                                            • Deposit Summary Report <br />
                                            • Consolidated Report <br />
                                            • Booking Sales and Commission Report <br />
                                            • Visa | MasterCard | Maestro | AMEX | JCB | Diners – Credit Card Option <br />
                                            • Commission Generated <br />
                                            • Option to show and hide commission and markup <br />
                                            • Can send the deposit detail and upload transaction receipt for the admin

                                        </ol>
                                    </div>
                                </div>

                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    Suppliers
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            • RED BUS <br />
                                            • ABHI BUS <br />
                                            • TBO <br />
                                            • HERMES
                                        </ol>
                                    </div>
                                </div>


                            </div>

                            <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                                <img src={Banner9} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                            </div>
                        </div>

                    </div>
                )}

                {activeSection === "central-reservation-system" && (
                    <div>
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="py-3 px-3 text-xl mb-10 font-bold w-fit">
                                    Central Reservation System
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            FBT Technology provides an online web based Hotel reservation system (HRS) for the hotel/hospitality booking industry for service providers. Our hotel reservation system (HRS) allows you to start online hotel reservation portal and customer management. The application is created to simplify the work of hotel reservation online. It provides users a unique, user friendly and easy to use interface that improves the way people use the web today. <br />With our application, your hotel website will be able to provide real both (online/offline) room booking within minutes, building your website to provide an E-commerce features with one-time set up fees. Subject to hotel’s requirements, our application can also provide connectivity to online payment portal to verify and accept credit card payments.
                                        </ol>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    SPECIAL FEATURES OF PORTAL
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            • Hotel contact information (address, telephone, fax, email, and internet), Hotel class (stars), full description, extra services, full room and room pricing information, review on hotel by registered users, time-sensitive availability and pricing. <br />
                                            • Powerful administration system. <br />
                                            • Booking History and Management. <br />
                                            • Room upgrades capabilities <br />
                                            • Easy to administrate <br />
                                            • Fully dynamic Advance search on numerous fields. <br />
                                            • CMS based Front end & Back end Multilanguage. <br />
                                            • Create sub-admin with restricted privilege. <br />
                                            • Unlimited room type & room numbers and Currency Support. <br />
                                            • Customized payment gateway (Paypal , payu, ccavenue etc.) <br />
                                            • Hotel details , description, map , facilities , photo gallery more in details. <br />
                                            • Email Alert on rooms reservation. <br />
                                            • Master rate module• Comprehensive & Advance hotel search ability on various parameters (city, room types, star, prices, and so on). <br />
                                            • Provides complete control over the inventory including room allocation and corresponding room rates. <br />
                                            • Administrator can edit all static pages from the admin panel through the Content Management Systems. No need to fumble through html, ftp and all. <br />
                                            • Guest Login/Register• Agent Login/Register <br />
                                            • Creating invoice online reservation dynamic billing and pay through online.

                                        </ol>
                                    </div>
                                </div>


                            </div>

                            <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                                <img src={Banner10} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                            </div>
                        </div>

                    </div>
                )}

                {activeSection === "tour-management-system" && (
                    <div>
                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="py-3 px-3 text-xl mb-10 font-bold w-fit">
                                    Tour Package System
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            If you have a Tour & Travel website and need doing frequent changes in the content, which is obvious as this kind of website if not updated regularly will lose the interest of the returning visitors of the website; who is looking for “What is New”. To handle this requirement of your new or existing travel website/portal you can plan a CMS (Content Management System) based on your website to create or upload the tour and travel packages details like itineraries, package cost, inclusion, Photo gallery etc. Very easily without comprising the look and feel, font, colour combination, placement etc that can be managed by a non-technical staff too.
                                        </ol>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row">
                            <div className="flex-1">
                                <div className="py-3 px-3 text-md mt-10 mb-4 font-bold w-fit">
                                    FBT TECHNOLOGY HOLIDAY CMS INCLUDE FOLLOWING FEATURES:
                                </div>
                                <div className="flex flex-col gap-2 items-start">
                                    <div className="px-5">
                                        <ol className="list-disc text-paragraph">
                                            • Secure Login• User friendly back office interface for defining holiday packages <br />
                                            • Booking Managment <br />
                                            • WYSIWYG Editor <br />
                                            • Admin Management <br />
                                            • Customer Management <br />
                                            • Country Management <br />
                                            • City Managment <br />
                                            • Currency Managment <br />
                                            • Edit, Add or Delete tour packages <br />
                                            • Hide/Show Packages, City, Country <br />
                                            • Filter option as per Domestic & International, Destination, Budget, Number of Nights, Theme <br />
                                            • Package validity <br />
                                            • Other information like Terms & Conditions, Important notes <br />
                                            • Enquiry tracking from back office system <br />
                                            • Auto email alert for enquiries <br />
                                            • CMS Support Both Offline and online booking <br />
                                            • Keep product / date / price information on your website consistent with latest information <br />
                                            • Incorporate credit card payments into your booking engine <br />
                                            • HTML5 & CSS3 Layouts


                                        </ol>
                                    </div>
                                </div>


                            </div>

                            <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                                <img src={Banner11} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                            </div>
                        </div>

                    </div>
                )}


            </div>

            <div className="container mx-auto mt-10 mb-10 p-4 md:mt-20 md:p-5">
                <div className="text-[18px] leading-[24px] md:text-[20px] md:leading-[26px] lg:text-[35px] lg:leading-[40px] mb-6 md:mb-8 font-light">
                    <span className="font-bold text-primary">Meet the People  </span><br />
                    <span className="font-bold text-secondary">We are Working With </span>
                </div>
                <div className="flex flex-col md:flex-row">
                    {/* Left side - Boxes */}
                    <div className="w-full md:w-1/4 pr-0 md:pr-4">
                        {boxes.map((box) => (
                            <div
                                key={box.id}
                                className={`p-3 md:p-4 mb-3 md:mb-4 cursor-pointer rounded-lg ${activeBox === box.id ? 'bg-primary text-white' : 'bg-gray-200'}`}
                                onClick={() => setActiveBox(box.id)}
                            >
                                {box.title}
                            </div>
                        ))}
                    </div>

                    {/* Right side - SVG images */}
                    <div className="w-full md:w-3/4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-4 justify-items-center">
                        {boxes.find(box => box.id === activeBox).svgs.map((svg, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-center w-40 h-40 border border-gray-300 rounded-lg shadow-md hover:shadow-2xl"
                            >
                                <img
                                    src={svg}
                                    alt={`SVG ${index + 1}`}
                                    className="max-w-full max-h-full p-2"
                                />
                            </div>
                        ))}
                    </div>


                </div>
            </div>



        </div>
    );
}

export default TravelPortalDevelopment;
