import React from 'react'
import Banner1 from "../../assets/product/travelSoftwareSolution/banner1.svg";
import Banner2 from "../../assets/product/xmlApiIntegrationSolution/banner2.svg";
import Banner4 from "../../assets/product/xmlApiIntegrationSolution/banner4.svg";
const NewTravelSoftware = () => {
  const specialFeatures = [
    {
      title: "Accessible Anytime From Anywhere",
      description:
        "TripControl.net is a web-based online travel management software. It's available 24 x 7 from anywhere in the world. You just required internet connectivity.",
    },
    {
      title: "Simplified Itinerary Creation Process",
      description:
        "It's child play when it comes to creating a tour itinerary in FBT. You can simply copy-paste your day-wise itinerary details or use advanced tools to create a more detailed itinerary.",
    },
    {
      title: "Multiple Hotel Options In One Location",
      description:
        "Want to suggest more than 1 Hotel option at one or more locations in your itinerary? Simply select a location and its hotel, and FBT will include them in your itinerary.",
    },
    {
      title: "Well Formatted Designer Itinerary",
      description:
        "With FBT, you can generate beautiful and impressive travel itineraries and quotes in minutes with stunning images and all required details.",
    },
    {
      title: "1 Click To Duplicate & Reuse Travel Itinerary",
      description:
        "You can easily create a duplicate itinerary from any previously created tour plans. It's just a 1-click task and done!",
    },
    {
      title: "Add Your Own Images In Day Wise Plans",
      description:
        "FBT itinerary planner allows you to add your own images in your day-wise itineraries. It also links your sightseeing with Google images and YouTube videos automatically.",
    },
    {
      title: "Printable PDF, Web & Mobile Friendly Travel Itineraries",
      description:
        " Our Advanced Itinerary Planner generates itineraries that look awesome when printed on paper. Also FBT automatically create a unique web page for each itinerary which looks perfect in desktop computers, laptops or mobile devices.",
    },
    {
      title: "Multiple Itinerary Options",
      description:
        "When you send itinerary emails to customer – Depending on your customer likings you can choose 2 pages, 4 pages or detailed multiple pages itinerary to be sent to your customers.",
    },
    {
      title: "Your Branding (Logo, Business Name & Contact Details)",
      description:
        " FBT itinerary planner allows you to add your own images in your day wise itineraries. What's more – it also links your sightseeing with google images and YouTube videos automatically.",
    },
    {
      title: "Unlimited Itineraries & Unlimited Revisions",
      description:
        " You can create as many itineraries as you want and you can revise them as and when you get new updates from customer. No restrictions at all.",
    },
    {
      title: "Customised (FIT) & Group Tour Itineraries",
      description:
        "You can create both customised and group tour itineraries with multiple departure dates. You can add multiple customers or prospects in any group itineraries for any departure dates.",
    },
    {
      title: "100s Of Ready To Use Itineraries",
      description:
        "While you can create your own itinerary from scratch, you can also use FBT master itinerary data bank to quickly duplicate and send to customer when you are running short of time. We have covered almost all travel destinations itineraries in our master itinerary data bank.",
    },
    {
      title: "65000+ Activities & Sightseeing Database",
      description:
        " We have put together database of lots and lots of sightseeing and activities in our itinerary planner. We have even gone further, when you add any sightseeing or point of interest or destination in itinerary our system automatically links it to images and Videos for your customer pleasure.",
    },
    {
      title: "30 Lacs+ Hotels Database",
      description:
        " We have more than 6 lacs+ hotels database with images, indoor/outdoor features, check in – check out time and contact details. When you select any of the hotel to add in your itinerary, we automatically add hotel details with images in your itinerary for your customer pleasure.",
    },
    {
      title: "Complete Lead History",
      description:
        " FBT lead history feature helps you track all your staff activity in any given lead eg. how many itineraries sent, how many revisions made, what all notes or documents uploaded, what is status of hotel or flight booking etc. List is endless.",
    },
    {
      title: "Tours, Flights, Train, Pick Up Drop, Forex",
      description:
        " You can easily manage all types of travel sales leads in FBT. Its specifically built with requirement of Travel Agents in mind.",
    },
    {
      title: "Multiple User Options",
      description:
        " Got bigger team in your Travel Business? You can purchase as many users as you want under your main subscription. You can assign them specific leads or you can add multiple users in one big important leads and track their performance.",
    },
    {
      title: "Automated Itinerary Suggestions",
      description:
        " FBT smart itinerary planner will automatically suggest you ready itinerary from your past itineraries and master itinerary database according to the destinations you have selected in your lead to save your time.",
    },
    {
      title: "Create Hotel Vouchers",
      description:
        " You can easily create well formatted and company branded Hotel Stay Vouchers with all required details and send them to customer in few clicks",
    },
    {
      title: "All Your Customer Documents At One Place",
      description:
        " With FBT you can manage all your customer's tickets, passport photocopy, id cards, photos at one place within specific lead. No more jumble up for finding copy of important documents.",
    },

  ];
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-10 md:grid-cols-2">
          <div className="flex flex-col mr-5 gap-5 mt-20">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Travel </span>
              <span className="font-bold text-seconday"> Software Solutions </span>
            </div>
            <div className="text-paragraph font-normal text-base text-justify md:mb-10">
              FBT is a leading travel software solutions company with a client base in 4 countries...
            </div>
          </div>
          <div className="flex items-center justify-center w-full h-[70%] mt-20">
            <img src={Banner1} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
          </div>
        </div>
      </div>
      {/* Special Feature Section */}
      {/* Special Feature Section with Scroll */}
      <div className="container mx-auto p-5 mb-10 lg:px-15">
        <div className="mt-10 p-2 m-2 max-h-screen overflow-y-scroll">
          <div className="text-2xl font-semibold mt-8 mb-20">
            Special Feature
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center text-center mt-14">
            {specialFeatures.map((feature, index) => (
              <div
                key={index}
                className="border border-gray-300 rounded-lg p-6 shadow-sm h-full" // Added border, padding, shadow, and full height
              >
                <h3 className="text-sm font-bold">{feature.title}</h3>
                <p className="text-paragraph text-sm mt-6">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div >
  )
}

export default NewTravelSoftware
