import React from "react";
import Banner from "../../assets/privacypolicy/banner.png";

function privacypolicy() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5  mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
              <span className="font-bold text-primary">Privacy </span>
              <span className="font-bold text-seconday">Policy</span>
            </div>
            <div className="text-paragraph font-normal text-base text-justify md:mb-10">
              Welcome to TechAAG. We value your privacy and are committed to
              protecting your personal information. This Privacy Policy explains
              how we collect, use, and disclose your information when you use
              our services.
            </div>
          </div>
          <div>
            <img src={Banner} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           1) Information We Collect
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              <li>
                <span className="font-bold text-black">
                  Personal Information:
                </span>{" "}
                Name, email address, phone number, and other contact details.
              </li>
              <li>
                <span className="font-bold text-black"> Usage Data:</span>{" "}
                Information on how you interact with our website and services.
              </li>
              <li>
                <span className="font-bold text-black">
                  Cookies and Tracking Technologies:
                </span>{" "}
                Data collected through cookies, web beacons, and similar
                technologies.
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           2) How We Use Your Information
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              <li>
                <span className="font-bold text-black">
                  To Provide and Improve Services:
                </span>{" "}
                Ensuring you get the best experience from our services.
              </li>
              <li>
                <span className="font-bold text-black">
                  To Communicate with You:{" "}
                </span>{" "}
                Responding to your inquiries and sending service-related
                notifications.
              </li>
              <li>
                <span className="font-bold text-black">
                  For Marketing Purposes:{" "}
                </span>
                Sending promotional materials that may be of interest to you.
              </li>
              <li>
                <span className="font-bold text-black">
                  To Comply with Legal Obligations:{" "}
                </span>
                Ensuring compliance with legal requirements and regulations.
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           3) How We Share Your Information
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              <li>
                <span className="font-bold text-black">
                  With Service Providers:{" "}
                </span>{" "}
                Sharing information with third-party vendors who assist us in
                providing our services.
              </li>
              <li>
                <span className="font-bold text-black">
                  For Legal Reasons:{" "}
                </span>{" "}
                Disclosing information if required by law or to protect our
                rights.
              </li>
              <li>
                <span className="font-bold text-black">
                  With Your Consent:{" "}
                </span>
                Sharing your information in ways you have consented to.
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           4) Security of Your Information
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              We implement security measures to protect your personal
              information from unauthorized access and use. However, no internet
              transmission is completely secure, and we cannot guarantee
              absolute security.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           5) Your Rights
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              <li>
                <span className="font-bold text-black">
                  Access and Update:{" "}
                </span>{" "}
                You can access and update your personal information at any time.
              </li>
              <li>
                <span className="font-bold text-black">Opt-Out:</span> You can
                opt out of receiving marketing communications from us.
              </li>
              <li>
                <span className="font-bold text-black">Data Deletion:</span>
                You can request the deletion of your personal information.
              </li>
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
           6) Changes to This Privacy Policy
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              website.
            </ol>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="py-3 px-3 font-bold w-fit bg-background">
         7) Contact Us
          </div>
          <div className="px-5">
            <ol className="list-disc text-paragraph">
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:privacy@techaag.com" className="text-primary font-bold hover:text-seconday">privacy@techaag.com</a>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default privacypolicy;
