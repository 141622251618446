import React from "react";
import Banner1 from "../../assets/product/xmlApiIntegrationSolution/banner1.svg";
import Banner2 from "../../assets/product/xmlApiIntegrationSolution/banner2.svg";
import Banner3 from "../../assets/product/xmlApiIntegrationSolution/banner3.svg";
import Banner4 from "../../assets/product/xmlApiIntegrationSolution/banner4.svg";
import Banner5 from "../../assets/product/xmlApiIntegrationSolution/banner5.svg";
import flightIcon from "../../assets/product/flightIcon.svg";
import hotelIcon from "../../assets/product/hotelIcon.svg";
import carIcon from "../../assets/product/carIcon.svg";

function XMLApiIntegrationSolution() {
    const headings = [
        { id: "flight-booking-api", title: "Flight Booking API" },
        { id: "hotel-api", title: "Hotel API" },
        { id: "bus-api", title: "Bus API" },
        { id: "car-api", title: "Car API" },
    ];

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div>
            <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                <div className="grid grid-cols-1 justify-between items-center gap-10 md:grid-cols-2">
                    <div className="flex flex-col mr-5 gap-5 mt-20">
                        <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light">
                            <span className="font-bold text-primary">XML API  </span>
                            <span className="font-bold text-seconday">Integration Solution </span>
                        </div>
                        <div className="text-paragraph font-normal text-base text-justify md:mb-10">
                            In computer programming, an application programming interface (API) is a set of subroutine definitions, communication protocols, and tools for building software. In general terms, it is a set of clearly defined methods of communication among various components. A good API makes it easier to develop a computer program by providing all the building blocks, which are then put together by the programmer. An API may be for a web-based system, operating system, database system, computer hardware, or software library. An API specification can take many forms, but often includes specifications for routines, data structures, object classes, variables, or remote calls. POSIX, Windows API and ASPI are examples of different forms of APIs                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full h-[70%] mt-20">
                        <img src={Banner1} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>
            </div>

            {/* Table of Contents */}
            <div className="container mx-auto gap-5 p-5 mb-5 lg:px-15">
                <div className="font-bold text-xl md:text-2xl">
                    Table of Content
                </div>
                <div className="container flex flex-row gap-7 p-5 mb-10 mt-4">
                    {headings.map((heading) => (
                        <div
                            key={heading.id}
                            className="p-3 font-bold w-fit border border-gray-400 rounded-lg bg-background cursor-pointer text-center"
                            onClick={() => scrollToSection(heading.id)}>
                            {heading.title}
                        </div>
                    ))}
                </div>
            </div>

            {/* Sections */}

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="flight-booking-api" className="flex flex-row gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Flight
                            </div>
                            <div>
                                <img src={flightIcon} alt={`flight`} className="gap-2 " />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div className="py-3 px-3 mb-10 font-bold w-fit">
                            Flight Booking API
                        </div>
                        <div className="flex flex-col gap-2 items-start">
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    Our Flight APIs directly connect you to all the data you need to build an innovative website or android or IOS app. Your customers will be able to take benefits of the best fares on flights from global suppliers.                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Features
                            </div>

                            <div className="py-3 px-3  mt-4 w-fit">
                                <ol className="list-disc text-paragraph">
                                    • 11 NDC DIRECT CONNECT AIRLINES <br />
                                    • 750 + AIRLINES IN TOTAL• 225 LCC AIRLINES <br />
                                    • FBT Flight API is the largest LCC and FSC airline carriers’ platform available from a single API. <br />
                                    • FBT Flight API consumes the multi GDS inventory from different countries and continents to get best soto fares. <br />
                                    • FBT Flight API has 225 LCC and 750+ GDS airlines available from a single API. <br />
                                    • FBT Flight API aggregates information from hundreds of airlines providers, e-commerce platforms, search and mobile services with a single system to manage, search, book and support this vast content. <br />
                                    • FBT Flight API is available via an API, any Agency Desktop, and web based (agency/user login) portals. <br />
                                    • FBT API serves Online Travel Agencies (OTAs) ,Tour Operators (TOs), Corporate Online Booking Tools (OBTs), Meta-search Travel Management Companies (TMCs), Corporates Mobile Solution Providers. <br />
                                    • FBT Flight API offers what to include in an autobiography airline the cheapest fares from all of the world’s LCCs and GDS airlines. <br />
                                    • The API supports all of the airlines ancillary services, fare family types and bundled and unbundled services. <br />
                                    • FBT Flight API enables airlines with its huge distribution network and gives significant cost savings. <br />
                                    • FBT Flight API fully supports NDC and integration with any airline merchandising engines. <br />
                                    • FBT Flight API is the most comprehensive aggregated solution for managing distribution for all of the world’s LCCs and GDS airlines. FBT Flight API is available via three channels: <br />
                                    • API: B2B solution that is designed to integrate web and mobile applications to distribute and sell air content <br />
                                    • Desktop Agency provides a seamless integration to any of the Agency Desktop applications; <br />
                                    • Flight Booking Engine a web-based portal and user log-in online booking tool that integrates with Guests/Users/agents/corporate booking
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center w-full md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                        <img src={Banner2} alt="techaag" className="w-full h-auto mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />


            </div>

            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="hotel-api" className="flex flex-row gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Hotel
                            </div>

                            <div>
                                <img src={hotelIcon} alt={`hotel`} className="gap-2 " />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">

                        <div className="py-3 px-3 mb-10 font-bold w-fit bg-background">
                            Hotel API
                        </div>

                        <div className="flex flex-col gap-2 items-start">
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    FlyBird Tourism offer hotel API in very low cost with live data 6,500,00 Property in the globally. We have a separate team of expert developers who are seasoned professional when it comes to API consumption and integration. Whether it’s a travel and hospitality API or a payment gateway API or a Social Media APIs (Facebook, Twitter, Google+ etc.), we are thoroughly experienced. <br />
                                    <br />FBT Hotels API aggregates hotel content into a single API system from global hotel suppliers, consolidators, hotel chains, independent hotels and Online Travel Agencies (OTAs). FBT API provides comprehensive descriptions including room types, images, facilities, ancillaries of over 700,000 properties from global market . FBT XML/JSON API enables a distinct sorted hotels with low prices from multiple API suppliers and wholesalers..FBT API enables Online Travel Agencies (OTAs), Meta search and Travel Management Companies (TMCs) and Corporate companies to search, book and manage relevant hotel content.
                                </ol>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-8 w-fit bg-background">
                                Features
                            </div>

                            <div className="py-3 px-3  mt-4 w-fit">
                                <ol className="list-disc text-paragraph">
                                    • Automated Booking, Commission & Reporting: API offers an easy way to manage every booking, payment, and full commission management; Payment Management: <br />
                                    • API enables to define search parameters searches among many others resulting in valuable time-savings. In addition to this, FBT API matches thousands of hotel properties across the suppliers’ feeds. <br />
                                    • FBT API generates a list of all the hotels in a particular country, region, area by a set of filters or city and displays them on a map with some information about each property. <br />
                                    • FBT API offers full location data support for the unique requirements for web and mobile applications, combined with a fast response and light speed data through API.
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start w-full h-96 mb-20 md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                        <img src={Banner3} alt="techaag" className="w-full h-[100%] mt-10 mx-auto md:w-3/4  lg:w-full" />
                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />
            </div>


            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="bus-api" className="flex flex-row gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Bus
                            </div>
                            <div>
                                <img src={carIcon} alt={`bus`} className="gap-2 " />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">

                        <div className="py-3 px-3 mb-10 font-bold w-fit bg-background">
                            Bus API
                        </div>

                        <div className="flex flex-col gap-2 items-start">
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    FBT gives you freedom for wings to everywhere with large inventory of bus.Our Fast, Easy and Flexible Bus APIs directly connect you to all the inventory need to build a travel website or app on basis of your requirement. Your customers get great Facilities on our Bus API from any wherever in the world.
                                </ol>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Features
                            </div>

                            <div className="py-3 px-3  mt-4 w-fit">
                                <ol className="list-disc text-paragraph">
                                    • States Covered 25+• Routes 70,000 + <br />
                                    • 3,500 + Operators• Easy to get started<br />
                                    • Simple and well documented<br />
                                    • Largest bus routes inventory<br />
                                    • Super fast search speed and same results<br />
                                    • Built with modern web technologies (REST, JSON, etc…) <br />
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center w-full  md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                        <img src={Banner4} alt="techaag" className="w-full h-[60%] mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

                {/* Horizontal Line */}
                <hr className="border-t-2 border-gray-800 mt-20 mb-20 my-5" />


            </div>


            <div className="container mx-auto flex flex-col gap-7 p-5 mb-10 lg:px-15">

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div id="car-api" className="flex flex-row gap-2 mt-8 items-start">
                            <div className="py-3 px-3 mb-10 font-bold w-fit border border-gray-400 rounded-lg bg-background">
                                Car
                            </div>
                            <div>
                                <img src={carIcon} alt={`car`} className="gap-2 " />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">

                        <div className="py-3 px-3 mb-10 font-bold w-fit bg-background">
                            Car API
                        </div>

                        <div className="flex flex-col gap-2 items-start">
                            <div className="px-5">
                                <ol className="list-disc text-paragraph">
                                    FBT Technology providing best car API with multi Suppliers, Our Car Booking APIs directly connect you to all the data you need to build an innovative website or android or IOS app. Your customers will be able to take benefits of the best fares on cars from global suppliers.
                                </ol>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="flex flex-col-reverse md:flex-row">
                    <div className="flex-1">
                        <div className="flex flex-col gap-2 items-start">
                            <div className="py-3 px-3 font-bold mt-4 w-fit bg-background">
                                Features
                            </div>

                            <div className="py-3 px-3  mt-4 w-fit">
                                <ol className="list-disc text-paragraph">
                                    • Easy to get started• One way, round trip, Local and Outstation trips <br />
                                    • Simple and well documented <br />
                                    • Largest car routes inventory <br />
                                    • Super fast search speed and same results <br />
                                    • Built with modern web technologies (REST, JSON, etc…) <br />
                                    • Car Rental Booking Engine. <br />
                                    • Car Rental Reservation System. <br />
                                    • Connectivity with various suppliers <br />
                                    • Channel Management <br />
                                    • Easy to get started <br />
                                    • One way, round trip, Local and Outstation trips <br />
                                    • Simple and well documented <br />
                                    • Largest car routes inventory <br />
                                    • Super fast search speed and same results <br />
                                    • Built with modern web technologies (REST, JSON, etc…)• Car Rental Booking Engine. <br />
                                    • Car Rental Reservation System. <br />
                                    • Connectivity with various suppliers  <br />
                                    • Channel Management
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center w-full  md:w-[40%] mt-20 md:mt-0 order-1 md:order-2 justify-center">
                        <img src={Banner5} alt="techaag" className="w-full h-[60%] mx-auto md:w-3/4 lg:w-full" />
                    </div>
                </div>

            </div>



        </div>
    );
}

export default XMLApiIntegrationSolution;
