import React from "react";
import WhyChooseUs from "../../assets/Service/GraphicDesign/WhyChooseUs.png";
import GraphicBanner1 from "../../assets/Service/GraphicDesign/GraphicBanner1.svg";
import GraphicBanner2 from "../../assets/Service/GraphicDesign/GraphicBanner2.svg";
import Analysis from "../../assets/Service/GraphicDesign/analysis.svg";
import Calendar from "../../assets/Service/calendar.svg";
import Design from "../../assets/Service/design.svg";
import Maintenance from "../../assets/Service/maintenance.svg";
import setting from "../../assets/Service/setting.svg";
import ArrowRight from "../../assets/Service/arrowRight.svg";
import Target from "../../assets/Service/IOT&Smart/target.svg";
import Photoshop from "../../assets/Service/GraphicDesign/Adobe_Photoshop_CC_icon.svg";
import AfterEffect from "../../assets/Service/GraphicDesign/After Effect.svg";
import Figma from "../../assets/Service/GraphicDesign/Figma Logo.svg";
import Linkden from "../../assets/Service/GraphicDesign/in.svg";
import Illustrator from "../../assets/Service/GraphicDesign/Illustrator.svg";
import XD from "../../assets/Service/GraphicDesign/XD.svg";
import Sketch from "../../assets/Service/GraphicDesign/Sketch.svg";
import PremierPro from "../../assets/Service/GraphicDesign/Premier Pro.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function Graphicdesigns() {
  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] font-light mt-28">
              <span className="font-bold text-primary">Graphic Designs </span>
              <span className="font-bold text-primary"> & </span>
              <span className="font-bold text-seconday">UI/UX Designs</span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              With over{" "}
              <span className="text-primary font-bold">18 UI/UX </span> projects
              delivered with precision—our designs are not only visually
              compelling but ensure a seamless user experience across all
              platforms.
            </div>
          </div>
          <div className="mt-20">
            <img src={GraphicBanner1} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Why Should you
              <span className="text-primary font-bold"> Hire us </span>
              <span className="">
                for <br />
              </span>
              <span className="text-seconday font-bold">
                Graphic Design & UI/UX Design
              </span>
            </div>
            <div>
              <img src={WhyChooseUs} alt="techaag" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-background">
        <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
          <div className="grid grid-cols-1 gap-5 justify-center text-center">
            <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
              Our
              <span className="text-primary font-bold"> Graphic Designs </span>
              <span> & </span>
              <span className="text-seconday font-bold">
                UI/UX Designs Process
              </span>
            </div>
            <div className="hidden gap-10 justify-center text-left md:grid md:grid-cols-2">
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                  <img src={Calendar} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Consultation & Discovery</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Understanding Your Vision:
                    </span>{" "}
                    Conducting thorough consultations to understand your design
                    needs and goals.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Research & Analysis:
                    </span>{" "}
                    Analyzing industry trends and competitors to create a unique
                    design strategy.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                  <img src={Design} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Design & Prototyping</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Concept Development:
                    </span>{" "}
                    Creating initial design concepts and getting your feedback.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Prototyping:
                    </span>{" "}
                    Developing interactive prototypes to visualize the final
                    design.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                  <img src={Target} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Design Execution & Delivery</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Final Design:
                    </span>{" "}
                    Refining and finalizing the design based on feedback.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Delivery:
                    </span>{" "}
                    Providing all necessary design files and assets in the
                    required formats.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                  <img src={Maintenance} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Review & Iteration</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Ongoing Maintenance:
                    </span>{" "}
                    Offering maintenance WebDevelopments to keep your website
                    up-to-date and secure.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Continuous Improvement:
                    </span>{" "}
                    Regularly updating and optimizing the website based on user
                    feedback and new trends.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#5f135d] to-[#58043f]">
                  <img src={setting} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">Branding & Identity Design</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Brand Development:
                    </span>{" "}
                    Creating cohesive branding elements that represent your
                    brand identity.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Visual Identity:
                    </span>{" "}
                    Designing logos, color schemes, and other visual elements to
                    establish a strong brand presence.
                  </div>
                </div>
              </div>
              <div className="flex flex-row  items-center gap-5">
                <div className="p-3 rounded-md bg-gradient-to-r from-[#D800FB] to-[#751086]">
                  <img src={Analysis} alt="techaag" className="w-16" />
                </div>
                <div className="text-xl mb-2">
                  <span className="font-bold">User Testing & Analysis</span>
                  <div className="text-paragraph text-sm">
                    <span className="text-primary font-bold">
                      {" "}
                      Usability Testing:
                    </span>{" "}
                    Conducting user testing sessions to gather insights on the
                    design's usability and effectiveness.
                    <br />
                    <span className="text-primary font-bold">
                      {" "}
                      Performance Analysis:
                    </span>{" "}
                    Analyzing user feedback and performance metrics to make
                    data-driven design improvements.
                  </div>
                </div>
              </div>
            </div>

            {/* Swiper Slider */}
            <div className="grid grid-cols-1 gap-10 justify-center text-left md:grid-col-2 lg:grid-cols-2 md:hidden">
              <Swiper
                spaceBetween={10}
                navigation={false}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                className="mySwiper3"
              >
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#2C2C6F] to-[#3356C0]">
                      <img src={Calendar} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Consultation & Discovery
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Understanding Your Vision:
                        </span>{" "}
                        Conducting thorough consultations to understand your
                        design needs and goals.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Research & Analysis:
                        </span>{" "}
                        Analyzing industry trends and competitors to create a
                        unique design strategy.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#68DBF2] to-[#509CF5]">
                      <img src={Design} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Design & Prototyping</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Concept Development:
                        </span>{" "}
                        Creating initial design concepts and getting your
                        feedback.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Prototyping:
                        </span>{" "}
                        Developing interactive prototypes to visualize the final
                        design.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#6ADB6A] to-[#1D931D]">
                      <img src={Target} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Design Execution & Delivery
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Final Design:
                        </span>{" "}
                        Refining and finalizing the design based on feedback
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Delivery:
                        </span>{" "}
                        Providing all necessary design files and assets in the
                        required formats.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#086C61] to-[#009E8C]">
                      <img src={Maintenance} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">Review & Iteration</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Ongoing Maintenance:
                        </span>{" "}
                        Offering maintenance WebDevelopments to keep your
                        website up-to-date and secure.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Continuous Improvement:
                        </span>{" "}
                        Regularly updating and optimizing the website based on
                        user feedback and new trends.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col  items-center gap-5">
                    <div className="p-3 rounded-md bg-gradient-to-r from-[#5f135d] to-[#58043f]">
                      <img src={setting} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">
                        Branding & Identity Design
                      </span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Brand Development:
                        </span>{" "}
                        Creating cohesive branding elements that represent your
                        brand identity.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Visual Identity:
                        </span>{" "}
                        Designing logos, color schemes, and other visual
                        elements to establish a strong brand presence.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col items-center gap-5">
                    <div className="p-2 rounded-md bg-gradient-to-r from-[#D800FB] to-[#751086]">
                      <img src={Analysis} alt="techaag" className="w-16" />
                    </div>
                    <div className="text-xl mb-2">
                      <span className="font-bold">User Testing & Analysis</span>
                      <div className="text-paragraph text-sm text-center">
                        <span className="text-primary font-bold">
                          {" "}
                          Usability Testing:
                        </span>{" "}
                        Conducting user testing sessions to gather insights on
                        the design's usability and effectiveness.
                        <br />
                        <span className="text-primary font-bold">
                          {" "}
                          Performance Analysis:
                        </span>{" "}
                        Analyzing user feedback and performance metrics to make
                        data-driven design improvements.
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            {/* Swiper Slider end */}
          </div>
        </div>
      </div>

      <div className="container mx-auto gap-10 relative p-5 md:mt-20 md:mb-5 lg:px-15">
        <div className="text-[32px] w-full leading-[40px] sm:text-[35px] sm:leading-[45px]  font-light mb-5">
          Technology we use for
          <br />
          <span className="font-bold text-primary"> App </span>
          <span className="font-bold text-seconday">Development</span>
        </div>
        <div className="grid grid-cols-1 gap-10 justify-between items-center md:grid-cols-2">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-12 mt-5 justify-center hidden items-center md:grid">
            {[
              { name: "Photoshop", image: Photoshop },
              { name: "After Effect", image: AfterEffect },
              { name: "Figma", image: Figma },
              { name: "Illustrator", image: Illustrator },
              { name: "Linkden", image: Linkden },
              { name: "Adobe XD", image: XD },
              { name: "Premier Pro", image: PremierPro },
              { name: "Sketch", image: Sketch },
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="px-4 shadow-2xl h-28 rounded-lg w-28 flex items-center transition-transform duration-300 group-hover:-translate-y-2">
                  <img src={item.image} alt={item.name} className="w-full" />
                </div>
                <p className="text-center mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-5 lg:px-5 md:hidden">
            <Swiper
              spaceBetween={10}
              navigation={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode, Pagination, Thumbs]}
              className="mySwiper4"
            >
              {[
                { name: "Photoshop", image: Photoshop },
                { name: "After Effect", image: AfterEffect },
                { name: "Figma", image: Figma },
                { name: "Illustrator", image: Illustrator },
                { name: "Linkden", image: Linkden },
                { name: "Adobe XD", image: XD },
                { name: "Premier Pro", image: PremierPro },
                { name: "Sketch", image: Sketch },
              ].map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-auto h-32">
                    <img src={item.image} alt={item.name} className="w-full" />
                    <p className="text-center mt-2">{item.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:px-5">
            <img
              src={GraphicBanner2}
              alt="techaag"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 text-[#EF7F1A] hover:text-blue-900 mt-2 md:flex-row md:items-start md:justify-start md:mt-2">
          <span className="font-semibold text-md text-center md:text-left">
            Check Mobile Development Projects
          </span>
          <img src={ArrowRight} alt="techaag" />
        </div>
      </div>
    </div>
  );
}

export default Graphicdesigns;
