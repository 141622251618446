import React, { useState } from "react";
import "./careers.css"
import emailjs from "emailjs-com";
import Future from "../../assets/careers/futuretogether.png";
import Parties from "../../assets/careers/officeparties.png";
import Annual from "../../assets/careers/annualtrips.png";
import Weekly from "../../assets/careers/weekly.png";
import WorkLife from "../../assets/careers/lifebalance.png";
import Technologies from "../../assets/careers/technologies.png";
import Health from "../../assets/careers/healthinsurance.png";
import Success from "../../assets/careers/success.svg"
import { Accordion } from "flowbite-react";
import { RiMapPin2Fill, RiSuitcase2Fill } from "@remixicon/react";
import { Modal } from "flowbite-react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
import { Autoplay, FreeMode, Pagination, Thumbs } from "swiper/modules";

function Careers() {
    const [openModal, setOpenModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [currentJob, setCurrentJob] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        resumeLink: "", // Added for resume link
        highestQualification: "",
        experience: "",
        remarks: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send form data via EmailJS
        emailjs
            .send(
                "service_2wqn3ze",
                "template_jaouv7i", 
                {
                    ...formData,
                    resume: formData.resumeLink, 
                },
                "vF2GvwR9Or4yS-igh" 
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Form successfully submitted! We'll be in touch.");
                    setOpenModal(false)
                },
                (error) => {
                    console.log(error.text);
                    alert("There was an error submitting the form.");
                }
            );
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const jobs = [
        {
            title: "Cloud Solutions Architect",
            description: "Join our team to design scalable cloud solutions that meet our clients needs.",
            location: "Mumbai, Dubai, London",
            type: "Remote, On-Site",
            details: `2+ years of experience as a DevOps/Cloud Engineer in a cloud-based environment. 
                Proven experience with AWS or GCP cloud platforms, including their core services 
                (experience with Azure is a plus). In-depth knowledge of Infrastructure as Code 
                (IaC) tools like Terraform or Ansible (or equivalent). Expertise in CI/CD pipelines 
                and tools like Jenkins, GitLab CI/CD, or CircleCI. Solid understanding of containerization 
                technologies like Docker and Kubernetes. Experience with scripting languages like Python, 
                Bash, or PowerShell. Strong knowledge of Linux system administration. Strong understanding 
                of system monitoring tools (Prometheus, Grafana, Datadog, or equivalents).`
        },

        {
            title: "Project Manager",
            description: "Oversee project planning, execution, and delivery. Coordinate between teams, manage timelines, budgets, and ensure projects meet client expectations and deadlines",
            location: "Mumbai, Dubai, London",
            type: "Remote, On-Site",
            details: `2+ years of experience as a DevOps/Cloud Engineer in a cloud-based environment. 
                Proven experience with AWS or GCP cloud platforms, including their core services 
                (experience with Azure is a plus). In-depth knowledge of Infrastructure as Code 
                (IaC) tools like Terraform or Ansible (or equivalent). Expertise in CI/CD pipelines 
                and tools like Jenkins, GitLab CI/CD, or CircleCI. Solid understanding of containerization 
                technologies like Docker and Kubernetes. Experience with scripting languages like Python, 
                Bash, or PowerShell. Strong knowledge of Linux system administration. Strong understanding 
                of system monitoring tools (Prometheus, Grafana, Datadog, or equivalents).`
        },

        {
            title: "UI/UX Designer",
            description: "Create user-centric designs for websites and applications. Focus on enhancing user experience through intuitive design, wireframes, and prototypes, while maintaining brand consistency.",
            location: "Mumbai, Dubai, London",
            type: "Remote, On-Site",
            details: `2+ years of experience as a DevOps/Cloud Engineer in a cloud-based environment. 
                Proven experience with AWS or GCP cloud platforms, including their core services 
                (experience with Azure is a plus). In-depth knowledge of Infrastructure as Code 
                (IaC) tools like Terraform or Ansible (or equivalent). Expertise in CI/CD pipelines 
                and tools like Jenkins, GitLab CI/CD, or CircleCI. Solid understanding of containerization 
                technologies like Docker and Kubernetes. Experience with scripting languages like Python, 
                Bash, or PowerShell. Strong knowledge of Linux system administration. Strong understanding 
                of system monitoring tools (Prometheus, Grafana, Datadog, or equivalents).`
        },
        // Add more job objects here
    ];

    const slidesData = [
        {
            imgSrc: Parties,
            gradient: "from-[#6EE8FC] to-[#529FAC]",
            title: "Office Parties",
            description: "We celebrate everything from birthdays to work anniversaries and success stories!",
        },
        {
            imgSrc: Annual,
            gradient: "from-[#173953] to-[#004B84]",
            title: "Annual Trips",
            description: "Once a year, we forget everything and travel like there's no tomorrow.",
        },
        {
            imgSrc: Weekly,
            gradient: "from-[#F9E181] to-[#D2B43A]",
            title: "Weekly Tech Talks",
            description: "Knowledge is the only thing that grows when it's shared.",
        },
        {
            imgSrc: WorkLife,
            gradient: "from-[#6ADB6A] to-[#1F8D1F]",
            title: "Work/Life Balance",
            description: "Fixed shift timing and 5 days working to maintain a healthy work/life balance.",
        },
        {
            imgSrc: Technologies,
            gradient: "from-[#68DBF2] to-[#509CF5]",
            title: "Technologies and Clients",
            description: "We offer cutting edge technologies and world class clients to work with.",
        },
        {
            imgSrc: Health,
            gradient: "from-[#002029] to-[#116A82]",
            title: "Health Insurance",
            description: "Company-sponsored health insurance for the wellbeing of all employees.",
        },
    ];

    const openJobModal = (jobTitle) => {
        setCurrentJob(jobTitle);
        setOpenModal(true);
    };

    const closeSuccessModal = () => {
        setSuccessModal(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div>
            <div className="container mx-auto gap-5 p-5  mb-10 lg:px-15">
                <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
                    <div className="flex flex-col gap-5">
                        <div className="text-[28px] leading-[36px] lg:text-[45px] mt-20 lg:leading-[60px] font-light">
                            <span className="font-bold text-primary">Join Us </span> and{" "}
                            <span className="font-bold text-seconday">
                                Shape the <br />
                                Future Together
                            </span>
                        </div>
                        <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
                            With over{" "}
                            <span className="text-primary font-bold">15 successful</span> web
                            development projects under our belt, we pride ourselves on
                            delivering exceptional digital solutions that drive results.
                        </div>
                    </div>
                    <div className="mt-28">
                        <img src={Future} alt="techaag" className="mx-auto" />
                    </div>
                </div>
            </div>

            <div>
                <div className="container mx-auto gap-5 p-5 md:mt-20 mb-10 lg:p-16">
                    <div className="grid grid-cols-1 gap-5 justify-center text-center">
                        <div className="text-[32px] leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
                            What <span className="text-seconday font-bold">We Offer</span>
                        </div>

                        {/* Swiper Slider */}
                        <div className="grid grid-cols-1 justify-center">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                freeMode={true}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Autoplay, FreeMode, Pagination, Thumbs]}
                                className="mySwiper"
                            >
                                {slidesData.map((slide, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="flex flex-col items-center gap-5 h-full p-5" style={{ border: "2px solid #eee" }} >
                                            <div className={`p-4 rounded-md bg-gradient-to-r ${slide.gradient}`}>
                                                <img src={slide.imgSrc} alt={slide.title} className="w-20 h-20 object-contain" />
                                            </div>
                                            <div className="text-lg mb-5">
                                                <span className="font-bold">{slide.title}</span>
                                                <div className="text-paragraph text-sm">{slide.description}</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>

            {/* Careers Section */}
            <div>
                <div className="container mx-auto flex flex-col gap-10 p-5 mb-10 lg:p-16">
                    <div className="grid grid-cols-1 gap-5 justify-between md:grid-cols-2">
                        <div className="text-[32px] leading-[40px] sm:text-[35px] sm:leading-[45px] font-light">
                            If you think you can make it, <br />
                            <span className="font-bold text-primary"> Check Openings</span> and <span className="font-bold text-seconday"> Apply</span>
                        </div>
                        <div className="flex flex-col gap-5 lg:px-5 md:order-last"></div>
                    </div>

                    <Accordion>
                        {jobs.map((job, index) => (
                            <Accordion.Panel key={index}>
                                <Accordion.Title>
                                    <div>{job.title}</div>
                                    <div className="text-paragraph font-light">{job.description}</div>
                                    <div className="text-paragraph flex gap-2 mt-2 items-center font-light">
                                        <RiMapPin2Fill size={20} className="my-icon" />
                                        {job.location}
                                        <RiSuitcase2Fill size={20} className="my-icon" />
                                        {job.type}
                                    </div>
                                </Accordion.Title>
                                <Accordion.Content>
                                    <div className="font-bold">About Position:</div>
                                    <div className="mb-3">{job.details}</div>
                                    <div className="my-3 flex justify-center items-center">
                                        <button
                                            onClick={() => openJobModal(job.title)}
                                            className="bg-primary w-fit text-white px-4 py-3 rounded-lg"
                                        >
                                            Apply Now
                                        </button>
                                    </div>
                                </Accordion.Content>
                            </Accordion.Panel>
                        ))}
                    </Accordion>
                </div>
            </div>

            {/* Application Modal */}
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>
                    <span className="font-bold text-primary">Apply for</span>{" "}
                    <span className="text-seconday font-bold">{currentJob}</span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} className="space-y-8">
                        <div>
                            <label htmlFor="name" className="block mb-2 text-sm font-medium">
                                Your Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                                placeholder="abc@mail.com"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium">
                                Mobile Number
                            </label>
                            <input
                                type="number"
                                id="phone"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                                placeholder="1234567890"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="experience" className="block mb-2 text-sm font-medium">
                                No of Years Experience
                            </label>
                            <input
                                type="number"
                                id="experience"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                                placeholder="Years of Experience"
                                value={formData.experience}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Updated resume field */}
                        <div>
                            <label htmlFor="resumeLink" className="block mb-2 text-sm font-medium">
                                Resume Link
                            </label>
                            <input
                                type="url"
                                id="resumeLink"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                                placeholder="https://drive.google.com/your-resume-link"
                                value={formData.resumeLink}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="highestQualification" className="block mb-2 text-sm font-medium">
                                Highest Qualification
                            </label>
                            <input
                                type="text"
                                id="highestQualification"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                                placeholder="Bachelor"
                                value={formData.highestQualification}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="remarks" className="block mb-2 text-sm font-medium">
                                Remarks
                            </label>
                            <input
                                type="text"
                                id="remarks"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5"
                                placeholder="Your Remarks"
                                value={formData.remarks}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="py-3 mx-auto rounded px-5 text-sm font-medium text-center bg-primary text-white w-full hover:bg-primary-800"
                        >
                            Submit
                        </button>
                    </form>
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default Careers;
