import React, { useState, useEffect, lazy, Suspense } from "react";

// Lazy load sections
const Section1 = lazy(() => import("./section1"));
const Section2 = lazy(() => import("./section2"));
// const Section4 = lazy(() => import("./section4"));
const NewSection3 = lazy(() => import("./NewSection3"));
const Aboutus = () => {

    const [sectionsInView, setSectionsInView] = useState({
        section1: false,
        section2: false,
        newSection3: false,
        section4: false,
    });

    useEffect(() => {
        const sectionElements = document.querySelectorAll("[data-section]");
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const section = entry.target.getAttribute("data-section");
                        setSectionsInView((prevState) => ({ ...prevState, [section]: true }));
                    }
                });
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.1, // Load section when it's 10% in view
            }
        );

        sectionElements.forEach((el) => observer.observe(el));

        return () => {
            sectionElements.forEach((el) => observer.unobserve(el));
        };
    }, []);
    return (
        <>
            {/* Section 1 */}
            <div data-section="section1">
                {sectionsInView.section1 && (
                    <Suspense fallback={<div className="text-center">Loading...</div>}>
                        <Section1 />
                    </Suspense>
                )}
            </div>

            {/* Section 2 */}
            <div data-section="section2">
                {sectionsInView.section2 && (
                    <Suspense fallback={<div className="text-center">Loading...</div>}>
                        <Section2 />
                    </Suspense>
                )}
            </div>

            {/* New Section 3 */}
            <div data-section="newSection3">
                {sectionsInView.newSection3 && (
                    <Suspense fallback={<div className="text-center">Loading...</div>}>
                        <NewSection3 />
                    </Suspense>
                )}
            </div>

             {/* New Section 3
             <div data-section="newSection3">
                {sectionsInView.newSection3 && (
                    <Suspense fallback={<div className="text-center">Loading...</div>}>
                        <Section4 />
                    </Suspense>
                )}
            </div> */}
        </>
    )
}

export default Aboutus
