/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Contact from "../../assets/contactus.png";
import Location from "../../assets/MapPoint.svg";
import Telephone from "../../assets/telephone.png";
import phone from "../../assets/icon/telphone.svg";
import Mail from "../../assets/icon/email.svg";
import emailjs from "emailjs-com";

function Contactus() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    companySize: "",
    subject: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
        "service_2wqn3ze",
        "template_jaouv7i", 
        {
            ...formData,
        },
        "vF2GvwR9Or4yS-igh" 
    )
    .then((result) => {
        console.log(result.text);
        alert("Form successfully submitted! We'll be in touch.");
        // Clear the form after submission
        setFormData({
            name: "",
            email: "",
            mobile: "",
            company: "",
            companySize: "",
            subject: "",
            message: ""
        });
    }, (error) => {
        console.log(error.text);
        alert("There was an error submitting the form.");
    });
};


  const locations = [
    {
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57749.72576147956!2d55.26099726274976!3d25.224869037553784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43a3d7c20ca7%3A0x2de6aeb512f1f1c9!2sOffice!5e0!3m2!1sen!2sin!4v1725883140369!5m2!1sen!2sin",
      location: "Office No. 400-70,4th Floor, Fahidi Height Office Tower, Bank Street Mankhol Bur Dubai, Dubai-UAE",
      img: Location
    },
    {
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1155260.3264832138!2d35.01953004204956!3d55.56079422865487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54afc73d4b0c9%3A0x3d44d6cc5757cf4c!2sMoscow%2C%20Russia!5e0!3m2!1sen!2sin!4v1725883273583!5m2!1sen!2sin",
      location: "Russia 129226 Moscow, Str.Selskpkhozaistvennaya - 4 CT. 9 office K9 M VDKH.",
      img: Location
    },
    {
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.1894986466427!2d58.53759497438649!3d23.59753589462959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9694cb3630b%3A0x92b82ef5f5f72590!2sMilestone%20Travel%20Agency!5e0!3m2!1sen!2sin!4v1725883377871!5m2!1sen!2sin",
      location: "Mile Stone Travels & Tour. Ruwi Main Market P O BOX 678 PC 131 Opp Old Poilce Station Ruwi, Muscat, Sultanate of Oman",
      img: Location
    }, {
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.836985393374!2d72.89131507404421!3d19.114806050755433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c80bfb18a7f1%3A0xac7b2587950de542!2sOberoi%20Garden%20Estate%2C%20Cityflo%20Stop!5e0!3m2!1sen!2sin!4v1722843244327!5m2!1sen!2sin",
      location: "Unit No 4068 B wings Oberoi Garden Estate Chandivali, Maharashtra",
      img: Location
    }

  ]

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div>
      <div className="container mx-auto gap-5 p-5 mb-10 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5 md:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="text-[28px] leading-[36px] lg:text-[45px] lg:leading-[60px] mt-20 font-light">
              <span className="font-bold text-primary">
                Linking Visions,
                <br />
              </span>{" "}
              <span className="font-bold text-seconday">
                Crafting Excellence
              </span>
            </div>
            <div className="text-paragraph font-normal text-base md:w-[90%] md:mb-10">
              With over 15 successful AI & ML projects under our belt, we pride
              ourselves on delivering exceptional digital solutions that drive
              results.
            </div>
            <Link
              to="/portfolio"
              className="bg-gradient-to-r from-gradient-3 to-gradient-4 w-fit text-white p-5 rounded-lg"
            >
              Check Our Works
            </Link>
          </div>
          <div className="mt-28">
            <img src={Contact} alt="techaag" className="mx-auto" />
          </div>
        </div>
      </div>
      <div className="container mx-auto gap-5 p-5 md:mt-20 mb-20 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5">
          <div className="text-[32px] font-bold leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
            Our <span className="text-primary">Global</span>
            <span className="text-seconday"> Presence</span>
          </div>
          {/* <div>
            <img src={Contact} alt="techaag" className="mx-auto" />
          </div> */}
          <div className="grid grid-cols-1 justify-between items-center gap-10 md:grid-cols-2">
            {locations.map((item, index) => (
              <div className="grid gap-2" key={index}>
                <iframe
                  src={item.src}
                  width="100%"
                  height="250"
                  className="border-2 rounded-2xl"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="flex flex-row gap-2 items-center p-5 shadow-lg rounded-2xl bg-background">
                  <img src={item.img} alt="location" className="rounded" />
                  <p>{item.location}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto gap-5 p-5 md:mt-20 md:mb-20 lg:px-15">
        <div className="grid grid-cols-1 justify-between items-center gap-5">
          {/* <div>
            <img src={Contact} alt="techaag" className="mx-auto" />
          </div> */}
          <div className="grid grid-cols-1 justify-between  gap-10 md:grid-cols-2">
            <div className="grid gap-5 mx-auto">
              <img
                src={Telephone}
                alt="techaag"
                className="mx-auto max-w-72 w-full mb-5"
              />
              <div className="flex items-center gap-2">
                <img src={phone} alt="techaag" className="max-w-8"></img>
                <a href="tel:+971-507913523" className="hover font-bold">
                  +971-507913523
                </a>
              </div>
              <div className="flex items-center gap-2">
                <img src={Mail} alt="techaag" className="max-w-8"></img>
                <a href="mailto:info@techaag.com" className="hover font-bold">
                  info@techaag.com
                </a>
              </div>
              <div className="flex items-center gap-2">
                <img src={phone} alt="techaag" className="max-w-8"></img>
                <a href="tel:+971-507913523" className="hover font-bold">
                  +971-507913523
                </a>
              </div>
            </div>
            <div className="grid gap-2">
              <div className="text-[32px] font-bold text-center leading-[40px] mb-5 sm:text-[35px] sm:leading-[55px]">
                <span className="text-primary">Contact</span>
                <span className="text-seconday"> Us</span>
              </div>
              <form onSubmit={handleSubmit} className="space-y-8">
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Your Name"
                    required
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Work email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@flowbite.com"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Mobile Number
                    </label>
                    <div className="relative flex z-0 w-full mb-2 group">
                      <input
                        type="number"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="shadow-sm bg-gray-50 border rounded-r-lg border-gray-300 text-gray-900 text-sm focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        placeholder="1234567887"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Company Name"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="companySize" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Company Size
                    </label>
                    <input
                      type="text"
                      id="companySize"
                      name="companySize"
                      value={formData.companySize}
                      onChange={handleChange}
                      className="shadow-sm bg-gray-50 border rounded-lg border-gray-300 text-gray-900 text-sm focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="50"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Let us know how we can help you"
                    required
                  />
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Your message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="6"
                    value={formData.message}
                    onChange={handleChange}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Leave a comment..."
                    required
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="py-3 mx-auto rounded px-5 text-sm font-medium text-center bg-primary text-white w-full hover:bg-primary-800"
                >
                  Send message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
